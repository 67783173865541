import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
    { path: 'design', loadChildren: './design/design.module#DesignModule' },
    { path: 'develop', loadChildren: './develop/develop.module#DevelopModule' },
    { path: 'playground', loadChildren: './playground/playground.module#PlaygroundModule' },
    { path: 'home', component: HomeComponent, data: { state: 'home' } },
    { path: '', pathMatch: 'full', redirectTo: 'home' }
];

interface ExtraOptions {
    scrollPositionRestoration?: 'disabled' | 'enabled' | 'top';
}

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
