import { InjectFlags, InjectionToken, Injector, Type } from '@angular/core';

/**
 * The DialogInjector is a class that we use to provide additional DI (dependency injection) tokens to the {@link DialogComponent} and the {@link InlineDialogComponent}. These tokens include the Config object of each.
 * @ignore
 */
export class DialogInjector implements Injector {
    constructor(
        private _parentInjector: Injector,
        private _additionalTokens: WeakMap<any, any>
    ) {
    }

    /**
     * @param token
     * @param notFoundValue
     * @param flags
     * @ignore
     */
    get<T>(
        token: Type<T> | InjectionToken<T>,
        notFoundValue?: T,
        flags?: InjectFlags
    ): T
    get(token: any, notFoundValue?: any)
    get(token: any, notFoundValue?: any, flags?: any): Injector {
        const value = this._additionalTokens.get(token);
        if (value) return value;
        return this._parentInjector.get<any>(token, notFoundValue, flags);
    }
}
