import { Component, Input } from '@angular/core';

interface SupportedPaymentIcon {
    label: string;
    width: number;
    height: number;
}

/**
 * Useful companion component for the footer. Can be used to render a list of payment icons.
 */
@Component({
    selector: 'hb-footer-payment-methods',
    templateUrl: './footer-payment-methods.component.html',
    styleUrls: ['./footer-payment-methods.component.scss']
})
export class FooterPaymentMethodsComponent {

    /**
     * Supported payment icons
     */
    supportedPaymentIcons: SupportedPaymentIcon[] = [
        {
            label:'ideal',
            width: 25,
            height: 25
        },
        {
            label: 'sepadirectdebit',
            width: 39,
            height: 26
        },
        {
            label: 'bancontact',
            width: 25,
            height: 25,
        },
        {
            label: 'dankort',
            width: 25,
            height: 25
        },
        {
            label: 'mastercard',
            width: 25,
            height: 25
        },
        {
            label: 'visa',
            width: 25,
            height: 25
        },
        {
            label: 'amex',
            width: 25,
            height: 25
        },
        {
            label: 'paypal',
            width: 25,
            height: 25
        },
        {
            label:'nexi',
            width: 25,
            height: 25
        },
        {
            label:'cartesbancaires',
            width: 25,
            height: 25
        }
    ];

    /**
     * Render only particular payment methods
     */
    @Input() supportedPaymentMethods: string[] = [];

    /**
     * Get icon properties if a supported icon is requested
     * @param iconName
     * @ignore
     */
    getIcon(iconName: string) {
        return this.supportedPaymentIcons.find(icon => icon.label === iconName);
    }

}
