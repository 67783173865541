import { Component, Input } from '@angular/core';
import { ControlContainer } from "@angular/forms";
import { BaseReactiveFormElement } from "../shared/base-element.reactiveform.class";

/**
 * Display a checkbox, which responds to the reactive form.
 * @example <hb-checkbox controlName="bike">
 *              Want many bikes
 *          </hb-checkbox>
 */
@Component({
    selector: 'hb-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent extends BaseReactiveFormElement {

    /***
     *  provide a name for binding in a larger reactive form
     */
    @Input() name: string;

    /***
     * @ignore
     */
    constructor(controlContainer: ControlContainer) {
        super(controlContainer);
    }

}
