import { AfterViewInit, Component, ContentChildren, ElementRef, QueryList, Renderer2 } from '@angular/core';
import { StepService } from "../../service/step.service";
import { StepItemComponent } from "../step-item/step-item.component";
import { ReactiveComponent } from "../../../../shared/reactive-component/reactive.component";
import { distinctUntilKeyChanged } from "rxjs/operators";

/**
 * Render a few steps that remain sticky. Using the StepService developers can open any step from within.
 * @example <hb-steps><hb-step-item></hb-step-item></hb-steps>
 * @example this.stepService.openIndex(2)
 */
@Component({
    selector: 'hb-steps',
    templateUrl: './steps.component.html',
    styleUrls: ['./steps.component.scss']
})
export class StepsComponent extends ReactiveComponent implements AfterViewInit {

    /**
     * Keep track of array of viewChildren
     * @ignore
     */
    list: StepItemComponent[] = [];

    /**
     * Get inner step items
     * @ignore
     */
    @ContentChildren(StepItemComponent) stepItems: QueryList<StepItemComponent>;

    /**
     * @ignore
     * @param stepService
     * @param renderer
     * @param elementRef
     */
    constructor(private stepService: StepService, private renderer: Renderer2, private elementRef: ElementRef) {
        super();
    }

    /**
     * @ignore
     */
    ngAfterViewInit(): void {
        this.list = this.stepItems.toArray();
        this.stepService.stepList$.next(this.list);

        this.stepItems.forEach((item, index) => item.index = index);

        this.subscriptions.add(
            this.stepService.currentIndex$.pipe(distinctUntilKeyChanged('index')).subscribe(item => {
                this.openIndex(item.index);
            })
        );

    }

    /**
     * @ignore
     * @param index
     */
    openIndex(index): void {
        this.stepService.currentIndex$.next({
            index: index
        });
    }

}
