import { NgModule } from '@angular/core';
import { LanguageSelectorComponent } from "./language-selector.component";
import { ReactiveFormsModule } from "@angular/forms";
import { DropdownModule } from "../../ui/reactive-form/dropdown/dropdown.module";
import { HbUIModule } from "../../hb-ui.module";
import { CommonModule } from "@angular/common";
import { SupportedLanguagesPipe } from './pipe/supported-languages.pipe';

@NgModule({
    declarations: [LanguageSelectorComponent, SupportedLanguagesPipe],
    imports: [
        CommonModule,
        DropdownModule,
        ReactiveFormsModule,
        HbUIModule
    ],
    exports: [
        LanguageSelectorComponent
    ]
})
export class LanguageSelectorModule {
}
