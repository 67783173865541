import { Component, Input } from '@angular/core';

export enum ContactchannelType {
    email = 'EMAIL',
    phone = 'PHONE',
    other = 'OTHER'
}

export interface ContactChannels {
    type: ContactchannelType;
    text: string;
    icon?: string;
    channel: string;
}

@Component({
    selector: 'hb-service-channels',
    templateUrl: './service-channels.component.html',
    styleUrls: ['./service-channels.component.scss']
})
export class ServiceChannelsComponent {

    /**
     * Dont render any opening times information
     */
    @Input() hideAvailability = false;

    /**
     * Renders a text on top of the contact info
     */
    @Input() header = 'We are here to help';

    /**
     * Renders a green dot on top of the contact info
     */
    @Input() isAvailable = true;

    /**
     * Render the following channels
     */
    @Input() channels: ContactChannels[];

    /**
     * Renders a text that displays the open until date.
     */
    @Input() closingHours: string;

    /**
     * Unavailable text
     */
    @Input() unavailableText = 'We are currently unavailable';
    @Input() availableText = 'Is available until';
    /**
     * Render component with white text
     */
    @Input() white = false;

    iconSize = 19;
}
