import { Component } from '@angular/core';
import { DialogRef } from "../../../../../../../../libs/angular/src/lib/ui/dialog/service/dialog-ref";

@Component({
    selector: 'styleguide-dialog-playground-example',
    templateUrl: './dialog-playground-example.component.html'
})
export class DialogPlaygroundExampleComponent {

    constructor(private dialogRef: DialogRef) {
    }

    clicked(): void {
        this.dialogRef.close('success');
    }

    clickedAlt(): void {
        this.dialogRef.close('abort');
    }

}
