import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
import { IconComponent } from '../../../icon/icon.component';

export interface DropdownValue {
    label: string;
    icon?: IconComponent;
    value: string;
}

@Injectable({
    providedIn: 'root'
})
export class DropdownService {

    selectedDropdownItem$: Subject<DropdownValue> = new Subject();

    selectOption(value: DropdownValue): void {
        this.selectedDropdownItem$.next(value);
    }
}
