/**
 * A plain object wrapper for an [Error]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error} object.
 * 
 * This is to circumvent standard error flow in code if needed.
 * 
 * For example, an error occuring in an Observable pipeline will close the pipeline (will not recieve any more observed events after an error occured).
 * To be able to handle success and error events continuosly and interchangeably, you can catch each error, wrap it with a plain SmoothError object and pass it on to the success flow.
 * In the success handler, you can check on the existence of the SmoothError wrapper to do desired error handling.
 * 
 * @example
 * const result$ = fromEvent(this.retryButton, 'click').pipe(
 *              switchMap(() => this.moodyEndpoint.get()),
 *              catchError(err => of(new SmoothError(err)))
 * );
 * result$.pipe(filter(it => it instanceof SmoothError)).subscribe(err => this.message = `this one failed with reason: ${err.reason}`);
 * result$.pipe(filter(it => !(it instanceof SmoothError))).subscribe(val => this.message = `got this one! ${val}`);
 * 
 *
 * 
 * // Please note that the above example could be written
 * // in a more compact/readable way using available utilities: 
 * 
 * 
 * const result$ = fromEvent(this.retryButton, 'click').pipe(
 *              switchMap(() => this.moodyEndpoint.get()),
 *              smoothenError()
 * );
 * result$.pipe(isValue()).subscribe(val => ...));
 * result$.pipe(isSmoothError()).subscribe(err => ...));
 */
export class SmoothError extends Error {

    constructor(public reason: any) {
        super();

        // Set the prototype explicitly,
        // otherwise 'instanceof SmoothError' does not work.
        Object.setPrototypeOf(this, SmoothError.prototype);
    }
}
