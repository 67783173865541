import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from "./component/tooltip.component";
import { TooltipDirective } from "./directive/tooltip.directive";

@NgModule({
    declarations: [
        TooltipDirective,
        TooltipComponent
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        TooltipDirective,
        TooltipComponent
    ],
    entryComponents: [
        TooltipComponent
    ]
})
export class TooltipModule {
}
