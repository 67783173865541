import { Component, Input } from '@angular/core';
import { ControlContainer } from "@angular/forms";
import { InputError } from "../input-error/input-error.component";
import { BaseReactiveFormElement } from "../shared/base-element.reactiveform.class";
import { createHbExpandFade } from "../../../animations/collapse-expand-fade/collapse-expand-fade.animation";

/**
 * Provide a textArea to a reactive form
 * @example  <hb-textarea controlName="story">
                A long story
            </hb-textarea>
 */
@Component({
    selector: 'hb-textarea',
    templateUrl: './textarea.component.html',
    animations: [
        createHbExpandFade({
            triggerName: 'expand'
        })
    ],
    styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent extends BaseReactiveFormElement {

    /***
     * sets the height of the field in any HTML unit.
     * @example 400px
     * @example 50%
     */
    @Input() height = '200px';

    /***
     * sets the width of the field in any HTML unit.
     * @example 400px
     * @example 50%
     */
    @Input() width: string;

    /***
     * provide a validation object to trigger form validation
     */
    @Input() validation: InputError;

    /**
     * @ignore
     * @param controlContainer
     */
    constructor(controlContainer: ControlContainer) {
        super(controlContainer);
    }

}
