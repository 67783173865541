import { NgModule } from '@angular/core';

import { HbUIModule } from './hb-ui.module';
import { HbCommonModule } from './hb-common.module';
import { HbContainerModule } from "./hb-container.module";

@NgModule({
    imports: [
        HbCommonModule,
        HbUIModule,
        HbContainerModule
    ],
    exports: [
        HbCommonModule,
        HbUIModule,
        HbContainerModule
    ]
})
export class HbAngularModule { }
