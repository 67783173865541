import { createHbExpand, HbExpand as expand } from "./collapse-expand/collapse-expand.animation";
import { HbCollapse as collapse } from "./collapse-expand/collapse-expand.animation";
import { HbCollapseFade as collapseFade } from "./collapse-expand-fade/collapse-expand-fade.animation";
import { HbFadeIn as fadeIn, HbFadeOut as fadeOut, createHbFade } from "./fade/fade.animations";
import { HbPopIn as popIn, HbPopOut as popOut, createHbPop } from "./pop/pop.animations";
import { HbSlideInLeft as slideInLeft, HbSlideOutLeft as slideOutLeft, HbSlideInUp as slideInUp, HbSlideOutUp as slideOutUp,
    HbSlideInRight as slideInRight, HbSlideOutRight as slideOutRight, HbSlideInDown as slideInDown, HbSlideOutDown as slideOutDown, HbSlideX as slideX, HbSlideY as slideY } from "./slide/slide.animations";
import { createHbExpandFade, HbExpandFade as expandFade } from "./collapse-expand-fade/collapse-expand-fade.animation";
import { HbSpeed } from './shared/animation-speeds';
import { HbEasing } from './shared/animation-easings';

/**
Re-usable animations usable with the useAnimation syntax
 */
export const HbAnimate = {
    collapse,
    expand,
    collapseFade,
    expandFade,
    fadeIn,
    fadeOut,
    popIn,
    popOut,
    slideInDown,
    slideOutDown,
    slideInLeft,
    slideOutLeft,
    slideX,
    slideInRight,
    slideOutRight,
    slideInUp,
    slideOutUp,
    slideY,
};

/**
 Constructors for creating your own triggerAnimations
 */
export { createHbExpand };
export { createHbExpandFade };
export { createHbFade };
export { createHbPop };

/**
 * Handlebar animation constants to use when creating own animations.
 */
export { HbEasing };
export { HbSpeed };