import { Component } from '@angular/core';
import { ControlContainer } from "@angular/forms";
import { BaseReactiveFormElement } from "../shared/base-element.reactiveform.class";

/**
 * Offer the user a few options as a native select field that is part of a reactive form.
 * @example  <hb-input-select controlName="name">
 *               <option value="google">Google</option>
 *                <option value="apple">Apple</option>
 *                <option value="facebook">Facebook</option>
 *            </hb-input-select>
 */
@Component({
    selector: 'hb-input-select',
    templateUrl: './input-select.component.html',
    styleUrls: ['./input-select.component.scss']
})
export class InputSelectComponent extends BaseReactiveFormElement {

    /**
     * @ignore
     * @param controlContainer
     */
    constructor(controlContainer: ControlContainer) {
        super(controlContainer);
    }

}
