import { Component, Input } from '@angular/core';

@Component({
    selector: 'hb-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss']
})
export class IconComponent {

    @Input() name: string;
    // TODO: define the default size of an icon
    @Input() height: number;
    @Input() width: number;
    @Input() inline: boolean;
    @Input() focusable: boolean;
    @Input() imageTag?: boolean;
}
