import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { ReactiveComponent } from "../../../../shared/reactive-component/reactive.component";
import { from } from "rxjs";
import { filter, switchMap } from 'rxjs/operators';

@Component({
    selector: 'hb-navbar-city-selector',
    templateUrl: './navbar-city-selector.component.html',
    styleUrls: ['./navbar-city-selector.component.scss']
})
export class NavbarCitySelectorComponent extends ReactiveComponent implements OnInit, OnChanges {

    /**
     * Add a label next to the dropdown
     */
    @Input() label: string;

    /**
     * Cities
     */
    @Input() cities: City[];

    /**
     * Change the no value label
     */
    @Input() noValueLabel: string;

    /**
     * Change the label when the user starts its search
     */
    @Input() searchLabel: string;

    /**
     * Change how long the dropdown may become
     */
    @Input() dropdownMaxHeight: string;

    /**
     * Change the dropdown width
     */
    @Input() dropdownWidth: string;

    /**
     * Provide a current city, the form will update to show this city as the selected city
     */
    @Input() currentCity: string;

    /**
     * Emits when a city has been entered in the form
     */
    @Output() selected: EventEmitter<string> = new EventEmitter<string>();

    /**
     * City Selector formGroup
     * @ignore
     *
     */
    citySelector: FormGroup = new FormGroup({
        city: new FormControl('')
    });

    /**
     * Patch city if executed with a prefilled city
     * @ignore
     */
    ngOnInit(): void {

        this.subscriptions.add(
            this.citySelector.valueChanges.pipe(
                // execute further in the next JS cycle, because 'dirty' gets populated only later in current cycle
                switchMap(v => from(Promise.resolve(v))),
                // make sure it's the user who made the change, not external service's reset
                filter(() => this.citySelector.dirty)
            ).subscribe(city => {
                this.citySelected(city);
            })
        );
    }

    /**
     * Patch city on changes
     * @ignore
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.currentCity?.currentValue) {
            this.citySelector?.controls['city'].reset(changes.currentCity?.currentValue);
        }
    }

    /**
     * Emit selected city
     * @ignore
     */
    citySelected(city: any): void {
        this.selected.emit(city);
    }

}

export class City {
    code: string;
    name: string;
}