import {
    AfterViewInit,
    Component,
    ContentChild,
    ContentChildren,
    Input,
    QueryList,
    TemplateRef,
} from '@angular/core';
import { ReactiveComponent } from '../../../../shared/reactive-component/reactive.component';
import { InfoBarItemComponent } from '../info-bar-item/info-bar-item.component';
import { InfoBarInsertionDirective } from '../../directive/insertion.directive';
import { HbConsoleLogger } from '../../../../shared/console-logger/handlebar-console.logger';

@Component({
    selector: 'hb-info-bar-roll',
    templateUrl: './info-bar-roll.component.html',
    styleUrls: ['./info-bar-roll.component.scss'],
})
export class InfoBarRollComponent extends ReactiveComponent implements AfterViewInit {

    /**
     * @ignore
     * ContentChild components
     */
    @ContentChildren(InfoBarItemComponent) infoBarItems: QueryList<InfoBarItemComponent>;

    /**
     * @optional
     * provide a height unit for pixel control of infoBar
     * @example 100px
     * @example 5em
     */
    @Input() height: string;

    /**
     * By default the roll goes from left to right. Alternatively go from right to left.
     */
    @Input() rightToleft = false;

    /**
     * Add a mobile slide speed in ms. It will take this long to show all items
     * @example 7000 (= 7 sec)
     */
    @Input() animationSpeed = 20000;

    /**
     * Inner directive for placing the childComponent inside the Dialog window
     * @ignore
     */
    @ContentChild(InfoBarInsertionDirective, {
        static: false,
        read: TemplateRef
    }) insertionPoint: InfoBarInsertionDirective;

    constructor() {
        super();
    }

    /**
     * Check and offer debugging for max child elements
     */
    ngAfterViewInit() {
        if (this.infoBarItems.length > 5) {
            HbConsoleLogger.error('InfoBarRollComponent', 'You placed more than 5 items in the infobar, which exceeds the supported amount');
        }
    }

}
