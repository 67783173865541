import { Component, Input } from '@angular/core';

/**
 * List of icons that we support
 */
export enum InfoBarIcon {
  CHECKMARK = 'check'
}

export enum InfoBarIconSize {
    SMALL = 20,
    MEDIUM = 40,
    LARGE = 44
}

@Component({
    selector: 'hb-info-bar-item',
    templateUrl: './info-bar-item.component.html',
    styleUrls: ['./info-bar-item.component.scss']
})
export class InfoBarItemComponent {

    fadeAnimation = false;

    /**
     * Set item to bold
     */
    @Input() bold = false;

    /**
     * Set the text to display in large text size instead of small.
     */
    @Input() largeText: boolean;

    /**
     * Provide an icon
     */
    @Input() icon: InfoBarIcon;

    /**
     * Set icon to larger size
     */
    @Input() iconSize: InfoBarIconSize = InfoBarIconSize.SMALL;

}
