import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptionCardComponent } from "./option-card.component";
import { ReactiveFormsModule } from "@angular/forms";


@NgModule({
    declarations: [
        OptionCardComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule
    ],
    exports: [
        OptionCardComponent
    ]
})
export class OptionCardModule {
}
