import { Subscription } from "rxjs";
import { OnDestroy } from "@angular/core";

/**
 * An abstract class that offers Subscriptions and destroys them after lifecycle
 */
export abstract class ReactiveComponent implements OnDestroy {

    /**
     * Keep track of Class subscribed Subscriptions
     */
    subscriptions: Subscription = new Subscription();

    /**
     * After lifecycle remove all current subscriptions
     */
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
