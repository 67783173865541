export type TooltipPositionType ='TOP' | 'BOTTOM';

export class TooltipConfig {

    /**
     * What to display inside tooltip
     */
    content: string;

    /**
     * Set a width of the tooltip
     */
    width?: number = 200;

    /**
     * Activate on click. If enabled, it will no longer show the tooltip on hover. A manual second click is required to close the tooltip
     */
    activateOnClick = false;

    /**
     * Set position of the tooltip, either up or down
     */
    position: TooltipPositionType = 'TOP';

}
