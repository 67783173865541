import { Component } from '@angular/core';
import { ControlContainer } from "@angular/forms";
import { BaseReactiveFormElement } from "../shared/base-element.reactiveform.class";

/***
 * Add a toggle to a reactiveForm. The state is either false (off) or true (on). This component does not support error handlers
 */
/***
 * @example <hb-toggle formControlName="formControl"> Label </hb-toggle>
 */
@Component({
    selector: 'hb-toggle',
    templateUrl: './toggle.component.html',
    styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent extends BaseReactiveFormElement {

    /**
     * @ignore
     * @param controlContainer
     */
    constructor(controlContainer: ControlContainer) {
        super(controlContainer);
    }

}
