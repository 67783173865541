import { NgModule } from '@angular/core';

import { UIModule } from './ui/ui.module';

@NgModule({
    imports: [
        UIModule,
    ],
    exports: [
        UIModule,
    ]
})
export class HbUIModule { }
