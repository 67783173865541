import { NgModule } from '@angular/core';

import { CommonModule } from './common/common.module';



@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        CommonModule,
    ]
})
export class HbCommonModule { }
