import { BrowserModule } from '@angular/platform-browser';
import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { DialogPlaygroundExampleComponent } from './playground/angular/dialog-playground/dialog-playground-example/dialog-playground-example.component';
import { CommonModule } from '@angular/common';
import { DialogPlaygroundInlineExampleComponent } from './playground/angular/dialog-playground/dialog-playground-inline-example/dialog-playground-inline-example.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HbCommonModule, HbUIModule } from '@handlebar/angular';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        DialogPlaygroundExampleComponent,
        DialogPlaygroundInlineExampleComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HbCommonModule,
        HbUIModule,
        CommonModule,
        AppRoutingModule
    ],
    providers: [
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
    ],
    entryComponents: [
        DialogPlaygroundExampleComponent,
        DialogPlaygroundInlineExampleComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
