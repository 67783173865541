import { Component, Input } from '@angular/core';

@Component({
    selector: 'hb-footer-list',
    templateUrl: './footer-list.component.html',
    styleUrls: ['./footer-list.component.scss']
})
export class FooterListComponent {

    @Input() header: string;

}
