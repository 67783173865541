import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, Type } from '@angular/core';
import { InlineDialogDirective } from "../directive/inline-dialog.directive";
import { DEFAULT_INLINE_DIALOG_CONFIG, DialogConfig, InlineDialogConfig } from "../dialog.config";
import { InlineDialogComponent } from "../component/inline-dialog/inline-dialog.component";
import { DialogRef, DialogType } from "./dialog-ref";
import { DialogInjector } from "../injector/dailog.injector";
import { BaseDialog } from "./base-dialog.class";

@Injectable({
    providedIn: 'root'
})
export class InlineDialogService extends BaseDialog {

    /***
     * Inline Dialog Config
     * @example {width: 600, height: 400, attachment: 'TOP', closeButton: false}
     */
    inlineDialogConfig: InlineDialogConfig = DEFAULT_INLINE_DIALOG_CONFIG;

    /**
     * Keep track of componentRef so we can remove it later
     * @ignore
     */
    dialogComponentRef: ComponentRef<InlineDialogComponent>;

    /**
     * keep track of a single dialogRef
     */
    dialogRef: DialogRef;

    /**
     * Keep track of the directive so we can clear it onDestroy
     * @ignore
     */
    element: InlineDialogDirective;

    /**
     * @param componentFactoryResolver We need this to get the factory for given component
     * @param appRef We use this to register the new instance of the component
     * @param injector We allow additional tokens to reach the service. This is used to provide the componentRef
     */
    constructor(
        public componentFactoryResolver: ComponentFactoryResolver,
        public appRef: ApplicationRef,
        public injector: Injector
    ) {
        super(componentFactoryResolver, appRef, injector);
    }

    /**
     *
     * @param componentType Provide the ChildComponent that we will render inside the DialogComponent
     * @param element Provide the {@link InlineDialogDirective} so we can render the Dialog inside these tags
     * @param config Provide an optional config. We support width, height and a closeButton value.
     * @ignore
     */
    open(componentType: Type<any>, element: InlineDialogDirective, config?: InlineDialogConfig): DialogRef {
        if (this.dialogRef) {
            this.closeDialogRef();
        }
        this.element = element;
        this.dialogRef = this.AddDialogToDOM(element, config);
        this.dialogComponentRef.instance.childComponentType = componentType;
        return this.dialogRef;
    }

    /**
     * Used by the open method to close the previous dialogRef if one was already open.
     */
    closeDialogRef(): void {
        this.dialogRef.close(null);
    }

    /***
     * Responsible isolated code for adding the dialog box to the view and appending a child component. Element is added to the InlineDialogDirective as a final child
     * @param componentType
     * @ignore
     */
    protected AddDialogToDOM(element: InlineDialogDirective, config?: DialogConfig): DialogRef {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(InlineDialogComponent);
        const dialogRef = new DialogRef(DialogType.INLINE);
        const dependencies = this.addDependenciesToDialog(dialogRef, config);
        element.viewContainerRef.clear();
        const componentRef = element.viewContainerRef.createComponent(componentFactory, 0, new DialogInjector(this.injector, dependencies));
        this.dialogComponentRef = componentRef;
        this.listenToClose(dialogRef);
        return dialogRef;
    }

    /***
     * Remove the dialog from the DOM
     * @ignore
     */
    protected removeDialogFromDOM(): void {
        if (this.dialogComponentRef) {
            this.appRef.detachView(this.dialogComponentRef.hostView);
        }
        this.element.viewContainerRef.clear();
        this.dialogComponentRef = undefined;
    }

}
