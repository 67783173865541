import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { DropdownItemComponent } from "../dropdown/dropdown-item/dropdown-item.component";

/**
 * Display a few options below an input that respond to user click. Make it a local template var, which you can add to the {@link InputComponent} as an autocomplete property
 * @example <hb-autocomplete options="['one', 'two']" #reference>
 *              Want many bikes
 *          </hb-autocomplete>
 */
@Component({
    selector: 'hb-autocomplete',
    templateUrl: './autocomplete.component.html',
    exportAs: 'hbAutoComplete',
    styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit, OnDestroy {

    /**
     * Available options for autocomplete
     */
    availableOptions: string[];

    /***
     * provide all available autocomplete options
     */
    @Input() options: string[];

    /***
     * sets auto complete to a width
     * @example 50%
     * @example 100px
     */
    @Input() width: string;

    /***
     * sets auto complete to full width. Overrides maxWidth value
     */
    @Input() block: boolean;

    /***
     * sets auto complete to visible
     */
    @Input() visible: boolean;

    /***
     * Provide a string that filters the list of optins. Useful for autocompleting with valid options based on user input
     */
    @Input() filter: Observable<string>;

    /**
     * Internal tracking of autocomplete filter
     */
    private autoCompleteFilter$: Subscription;

    /***
     * emits a string whenever the user has selected an option from the Option list
     */
    @Output() selected: EventEmitter<string> = new EventEmitter();

    ngOnInit(): void {
        this.availableOptions = this.options;
        if (this.filter) {
            this.autoCompleteFilter$ = this.filter.pipe(debounceTime(100)).subscribe(filter => {
                this.filterOptions(filter);
            });
        }
    }

    /**
     * @ignore
     */
    ngOnDestroy(): void {
        if (this.filter && this.autoCompleteFilter$) {
            this.autoCompleteFilter$.unsubscribe();
        }
    }

    /***
     * @param option {string}
     * Send the selected option to any listener
     * @ignore
     */
    selectOption(option: string): void {
        this.selected.emit(option);
    }

    /**
     * Filter through options
     * @param filter
     * @ignore
     */
    filterOptions(filter): void {
        const activeFilter = filter.trim().toLowerCase();
        this.availableOptions = this.options.filter(item =>
            item.toLowerCase().indexOf(activeFilter) !== -1 &&
            item !== activeFilter
        );
    }

    /**
     * Use to track elements using Trackby
     * @param index
     * @param element
     * @ignore
     */
    trackOptions(index: number, element: DropdownItemComponent): string {
        return element.value;
    }

}
