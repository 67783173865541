import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    OnDestroy,
    Type,
    ViewChild
} from '@angular/core';
import { DialogInsertionDirective } from "../../directive/insertion.directive";
import { InlineDialogConfig } from "../../dialog.config";
import { DialogRef } from "../../service/dialog-ref";

interface InlineDialogPosition {
    offsetLeft?: number;
    offsetTop?: number;
}


/**
 * Inline Dialog component. This component renders a white square with a box shadow inside the hbInlineDialogDirective. Do not add this template to the DOM directly!
 * This component is always dynamically generated by {@link InlineDialogService}. This allows us to place the dialog anywhere on the page and doesn't pollute the template. Please also only open
 * one inlineDialog at once. This helps keep the UX nice and sane.
 * Please note: the component you provide in the Service (in this example myComponent, should be added as an entryComponent in the AppModule. This so that the Angular compiler takes the component on build time.
 * @example inlineDialogService.open(MyComponent, InlinePlacementDirective);
 * @example inlineDialogService.open(MyComponent, InlinePlacementDirective, {width: 400, height: 300, closeButton: true})
 * @example dialog = inlineDialogService.open(MyComponent, InlinePlacementDirective); dialog.afterClosed().subscribe(result -> {})
 */
@Component({
    selector: 'hb-inline-dialog',
    templateUrl: './inline-dialog.component.html',
    styleUrls: ['./inline-dialog.component.scss']
})
export class InlineDialogComponent implements AfterViewInit, OnDestroy {

    /**
     * Determine the type of the child component, so it can fetch the component factory
     * @ignore
     */
    childComponentType: Type<any>;

    /**
     * Internal tracking of the parent wrapper
     */
    parentElement: HTMLElement;
    position: InlineDialogPosition = {
        offsetTop: null,
        offsetLeft: null
    };

    /**
     * Place to put the instance of the created component. So we can destory it on ngDestory
     * @ignore
     */
    public componentRef: ComponentRef<any>;

    /**
     * Insertion directive, used internally to place component
     * @ignore
     */
    @ViewChild(DialogInsertionDirective, {static: false}) insertionPoint: DialogInsertionDirective;

    /**
     *
     * @param componentFactoryResolver
     * @param cd
     * @param element
     * @param dialogRef
     * @param config
     */
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private cd: ChangeDetectorRef,
        private element: ElementRef,
        private dialogRef: DialogRef,
        public config: InlineDialogConfig
    ) {
    }

    /***
     * Load the child component into the view
     * @param componentType
     * @ignore
     */
    loadChildComponent(componentType: Type<any>): void {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
        const viewContainerRef = this.insertionPoint.viewContainerRef;
        viewContainerRef.clear();
        this.componentRef = viewContainerRef.createComponent(componentFactory);
    }

    /**
     * @ignore
     * On afterViewInit trigger the loadChild component because we need to know the template is ready
     */
    ngAfterViewInit(): void {
        if (this.childComponentType) {
            this.loadChildComponent(this.childComponentType);
            this.calculatePosition(this.element.nativeElement.parentElement);
        }
        this.cd.detectChanges();
    }

    /**
     * Calculate position of the inline Dialog
     */
    calculatePosition(parentElement: HTMLElement): void {
        this.position = {
            offsetTop: null,
            offsetLeft: null
        };
        const outerLeft = parentElement.offsetLeft - parentElement.clientWidth,
            outerTop = parentElement.offsetTop - this.config.height,
            outerRight = parentElement.offsetLeft + parentElement.clientWidth,
            outerBottom = parentElement.offsetTop + parentElement.clientHeight,
            middle = outerBottom - (parentElement.offsetHeight / 2) - (this.config.height / 2),
            center = (outerRight - parentElement.clientWidth / 2) - (this.config.width / 2);

        switch (this.config.attach) {
        case "BOTTOM":
            this.position = {
                offsetTop: outerBottom,
                offsetLeft: center
            };
            break;
        case "BOTTOMLEFT":
            this.position = {
                offsetTop: outerBottom,
                offsetLeft: outerLeft
            };
            break;
        case "BOTTOMRIGHT":
            this.position = {
                offsetTop: outerBottom,
                offsetLeft: outerRight
            };
            break;
        case "LEFT":
            this.position = {
                offsetLeft: outerLeft,
                offsetTop: middle
            };
            break;
        case "RIGHT":
            this.position = {
                offsetLeft: outerRight,
                offsetTop: middle
            };
            break;
        case "TOP":
            this.position = {
                offsetTop: outerTop,
                offsetLeft: center
            };
            break;
        case "TOPRIGHT":
            this.position = {
                offsetTop: outerTop,
                offsetLeft: outerRight
            };
            break;
        case "TOPLEFT":
            this.position = {
                offsetTop: outerTop,
                offsetLeft: outerLeft
            };
            break;
        default:
            break;
        }
        if (this.config.marginLeft) {
            this.position.offsetLeft =
                this.position.offsetLeft + this.config.marginLeft;

        } else if (this.config.marginTop) {
            this.position.offsetTop =
                this.position.offsetTop + this.config.marginTop;
        }
    }

    /**
     * Destory the componentRef
     * @ignore
     */
    ngOnDestroy(): void {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

    /**
     * Close the dialog
     * @ignore
     */
    close(): void {
        this.dialogRef.close(null);
    }
}
