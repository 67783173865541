import { Directive, ViewContainerRef } from '@angular/core';

/***
 * The inlineDialog directive is used on an ng-template to provide a hooking mechanism for the inline-dialog
 * @example <ng-template hbInlineDialog></ng-template>
 */
@Directive({
    selector: '[hbInlineDialog]'
})
export class InlineDialogDirective {

    /***
     * the viewContainerRef is used to get access to the DOM structure when this directive is passed along to the responsible inlineDialogService
     * @param viewContainerRef
     */
    constructor(public viewContainerRef: ViewContainerRef) {
    }

}
