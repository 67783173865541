import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { NavbarItem } from "../navbar/navbar.component";
import { contextFoldoutPanelAnimation } from "./navbar-foldout-panel.animation";
import { Router } from "@angular/router";

@Component({
    selector: 'hb-navbar-foldout-panel',
    templateUrl: './navbar-foldout-panel.component.html',
    animations: [
        contextFoldoutPanelAnimation()
    ],
    styleUrls: ['./navbar-foldout-panel.component.scss']
})
export class NavbarFoldoutPanelComponent {

    /**
     * Provie a language selector
     */
    @Input() languageSelector: TemplateRef<any>;

    /**
     * Control starting state of panel
     */
    @Input() open: boolean;

    /**
     * render the following navbar items
     */
    @Input() menuItems: NavbarItem[];

    /**
     * Listen to close event from inside panel
     */
    @Output() closed: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Item is clicked eventEmitter. Useful when emitNavigationMode is turned on and your project handles the anchors themselves.
     */
    @Output() itemClicked: EventEmitter<NavbarItem> = new EventEmitter<NavbarItem>();

    /**
     * Get active URl so it can highlight menu item as active
     */
    @Input() activeUrl: string;

    /**
     * This turns off the anchors of the Navigation items, and instead emits an event for the parent to
     */
    @Input() emitNavigationMode: boolean;

    constructor(private router: Router) {
    }

    /**
     * close panel from inside and emit
     */
    closePanel(): void {
        this.closed.emit();
    }

    navigateRoute(item): void {
        this.emitNavigationMode ?
            this.itemClicked.emit(item) :
            this.router.navigateByUrl(item.url, item.routerOptions);
    }

}
