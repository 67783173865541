import { AfterViewInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { ListItemComponent } from "./list-item/list-item.component";

export enum ListStyleType {
    disc = 'DISC',
    check = 'CHECK',
    number = 'NUMBER'
}

/**
 * List component. This is the wrapper around {@link ListItemComponent} for rendering a list on the page
 * @example <hb-list>
 *     <hb-list-item> My first list item </hb-list-item>
 * </hb-list>
 *  @example <hb-list listStyleType="CHECK">
 *     <hb-list-item> My first list item with checks </hb-list-item>
 *      <hb-list-item (click)="goNorth()"> My clickable item </hb-list-item>
 * </hb-list>
 */
@Component({
    selector: 'hb-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent implements AfterViewInit {

    /**
     * Provide a liststyleType (default: Disc). Options: DISC, CHECK or NUMBER
     */
    @Input() listStyle: ListStyleType = ListStyleType.disc;

    /**
     * Used for finding list child components and setting props based on this components Input
     * @ignore
     */
    @ContentChildren(ListItemComponent) listItems: QueryList<ListItemComponent>;

    /**
     * Component currently changes behavior of list items when we want to use Angular components to fake an ordered list
     * @ignore
     */
    ngAfterViewInit(): void {
        if (this.listStyle === ListStyleType.number) {
            this.listItems.map((item, i) => {
                item.renderNumber(i);
            });
        }
    }

}
