import { Component } from '@angular/core';
import { DialogRef } from "../../../../../../../../libs/angular/src/lib/ui/dialog/service/dialog-ref";

@Component({
    selector: 'styleguide-dialog-playground-inline-example',
    templateUrl: './dialog-playground-inline-example.component.html'
})
export class DialogPlaygroundInlineExampleComponent {

    constructor(private dialogRef: DialogRef) {}

    closeFromInside(): void {
        this.dialogRef.close('success');
    }

}
