import { animate, animateChild, group, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { HbEasing } from "../../../animations/shared/animation-easings";

export const hamburgerFadeAnimation = trigger('hamburgerFade', [
    transition('0 <=> 1', [
        group([query('@borderTopFade, @borderMiddleFade, @borderBottomFade', stagger(
            50,
            animateChild()
        ))])
    ])
]);


/***
 * @description animation top bar in hamburger
 */
export const borderTopFade = trigger('borderTopFade', [
    state('0', style({
        transform: 'translateY(-6px)',
        opacity: 1
    })),
    state('1', style({
        transform: 'translateY(-6px) translateX(-12px)',
        opacity: 0
    })),
    transition('0 <=> 1', animate(`0.15s ${HbEasing.easeInOut}`))
]);

/***
 * @description animation bottom bar in hamburger
 */
export const borderBottomFade = trigger('borderBottomFade', [
    state('0', style({
        transform: 'translateY(6px)',
        opacity: 1
    })),
    state('1', style({
        transform: 'translateY(6px) translateX(-12px)',
        opacity: 0
    })),
    transition('0 <=> 1', animate(`0.15s ${HbEasing.easeInOut}`))
]);

/***
 * @description animation middle bar in hamburger
 */
export const borderMiddleFade = trigger('borderMiddleFade', [
    state('0', style({
        transform: 'translateX(0px)',
        opacity: 1
    })),
    state('1', style({
        transform: 'translateX(-12px)',
        opacity: 0
    })),
    transition('0 <=> 1', animate(`0.15s ${HbEasing.easeInOut}`))
]);
