import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from "../common/pipes/pipes.module";
import { AutocompleteComponent } from "./reactive-form/autocomplete/autocomplete.component";
import { CheckboxComponent } from "./reactive-form/checkbox/checkbox.component";
import { InputComponent } from "./reactive-form/input/input.component";
import { InputErrorComponent } from "./reactive-form/input-error/input-error.component";
import { InputSelectComponent } from "./reactive-form/input-select/input-select.component";
import { TextareaComponent } from "./reactive-form/textarea/textarea.component";
import { RadioComponent } from "./reactive-form/radio/radio.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToggleComponent } from './reactive-form/toggle/toggle.component';
import { DialogModule } from "./dialog/dialog.module";
import { IconModule } from "./icon/icon.module";
import { ButtonModule } from "./button/button.module";
import { ListModule } from "./list/list.module";
import { DropdownModule } from "./reactive-form/dropdown/dropdown.module";
import { LinkModule } from "./link/link.module";
import { DirectivesModule } from "../common/directives/directives.module";
import { OverlayModule } from "./overlay/overlay.module";
import { HamburgerIconModule } from "./hamburger-icon/hamburger-icon.module";
import { StepsModule } from "./steps/steps.module";
import { TooltipModule } from "./tooltip/tooltip.module";
import { OptionCardModule } from "./reactive-form/option-card/option-card.module";
import { SocialChannelsModule } from "./social-channels/social-channels.module";
import { FormSectionModule } from "./reactive-form/form-section/form-section.module";
import { InfoBarModule } from './info-bar/info-bar.module';

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
        IconModule,
        ButtonModule,
        FormsModule,
        StepsModule,
        DirectivesModule,
        ListModule,
        InfoBarModule,
        DropdownModule,
        ReactiveFormsModule,
        DialogModule,
        LinkModule,
        OverlayModule,
        OptionCardModule,
        TooltipModule,
        LinkModule,
        SocialChannelsModule,
        HamburgerIconModule,
        FormSectionModule
    ],
    declarations: [
        AutocompleteComponent,
        CheckboxComponent,
        InputComponent,
        InputErrorComponent,
        InputSelectComponent,
        TextareaComponent,
        RadioComponent,
        ToggleComponent
    ],
    exports: [
        AutocompleteComponent,
        CheckboxComponent,
        InputComponent,
        IconModule,
        DialogModule,
        ListModule,
        ButtonModule,
        StepsModule,
        InputErrorComponent,
        InputSelectComponent,
        TextareaComponent,
        TooltipModule,
        HamburgerIconModule,
        DropdownModule,
        InfoBarModule,
        RadioComponent,
        ToggleComponent,
        LinkModule,
        SocialChannelsModule,
        OptionCardModule,
        OverlayModule,
        FormSectionModule
    ],

})
export class UIModule {
}
