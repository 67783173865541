import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../common/pipes/pipes.module';
import { NavbarModule } from './navbar/navbar.module';
import { UIModule } from '../ui/ui.module';
import { LanguageSelectorModule } from './language-selector/language-selector.module';
import { SupportedLanguagesPipe } from './language-selector/pipe/supported-languages.pipe';
import { ServiceChannelsModule } from './service-channels/service-channels.module';
import { OrderSummaryModule } from './order-summary/order-summary.module';
import { FooterModule } from './footer/footer.module';

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
        UIModule,
        NavbarModule,
        ServiceChannelsModule,
        OrderSummaryModule,
        FooterModule,
        LanguageSelectorModule
    ],
    providers: [
        SupportedLanguagesPipe
    ],
    exports: [
        NavbarModule,
        ServiceChannelsModule,
        FooterModule,
        LanguageSelectorModule,
        OrderSummaryModule
    ]
})
export class ContainerModule {}
