import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutoFocusDirective } from './auto-focus/auto-focus.directive';
import { CatchSmoothErrorDirective } from './catch-smooth-error.directive';
import { LetDirective } from './let.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        AutoFocusDirective,
        CatchSmoothErrorDirective,
        LetDirective,
    ],
    exports: [
        AutoFocusDirective,
        CatchSmoothErrorDirective,
        LetDirective,
    ]
})
export class DirectivesModule {}
