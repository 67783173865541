import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface FoldoutMenuState {
    open: boolean;
}

/**
 * Navbar Service. Used to get notified of important navbar events so the UI can adjust
 */
@Injectable({
    providedIn: 'root'
})
export class NavbarService {

    /**
     * Get foldout state
     */
    foldoutMenuState$: Subject<FoldoutMenuState> = new Subject();

    /**
     * Get navbar height
     */
    topbarAreaHeight$: Subject<number> = new Subject();

    /**
     * Provide the current foldout state
     * @param state
     */
    provideFoldoutState(state: FoldoutMenuState) {
        this.foldoutMenuState$.next(state);
    }

    /**
     * Provide current navbar height
     * @param height
     */
    provideTopAreaHeight(height: number) {
        this.topbarAreaHeight$.next(height);
    }

}
