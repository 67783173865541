import { Directive, ViewContainerRef } from '@angular/core';

/***
 * Dialog insertion directive is used inside the {@link InfoBarComponent} to inject the provided Component depending on animation type
 * @ignore
 */
@Directive({
    selector: '[hbInfoBarInsertion]'
})
export class InfoBarInsertionDirective {

    /**
     * Provide access to the viewContainerRef so we can use it to call the createComponent method from inside the dialogs
     * @param viewContainerRef
     */
    constructor(public viewContainerRef: ViewContainerRef) {
    }

}
