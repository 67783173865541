import { animate, animation, style, AnimationTriggerMetadata } from "@angular/animations";
import { AnimationConfiguration } from "../shared/animation-configuration.interface";
import { HbSpeed } from '../shared/animation-speeds';
import { createReversibleAnimation } from '../shared/reversible-animation';



/**
 HbSlideInLeft Animation
 @deprecated Use {@link HbSlideX} instead.
 @example  trigger('slideInLeft', [
    transition(':enter',
        useAnimation(HbSlideInLeft, {
            params: {
                time: HbSpeed.breeze,
                easing: 'ease-in-out',
                delay: 0,
                parameters: { translationIn: 0, translationOut: '-100%' }
            }
        })
 )
 ])
 */
export const HbSlideInLeft = animation([
    style({
        transform: 'translateX({{ translationOut }})'
    }),
    animate('{{ time }} {{ delay }} {{ easing }}', style({ transform: 'translateX({{ translationIn }})' }))
]);



/**
 HbSlideOutLeft Animation
 @deprecated Use {@link HbSlideX} instead.
 @example  trigger('slideOutLeft', [
    transition(':leave',
        useAnimation(HbSlideOutLeft, {
            params: {
                time: HbSpeed.breeze,
                easing: 'ease-in-out',
                delay: 0,
                parameters: { translationIn: 0, translationOut: '-100%' }
            }
        })
 )
 ])
 */
export const HbSlideOutLeft = animation([
    style({
        transform: 'translateX({{ translationIn }})'
    }),
    animate('{{ time }} {{ delay }} {{ easing }}', style({ transform: 'translateX({{ translationOut }})' }))
]);



/**
 HbSlideInRight Animation
 @deprecated Use {@link HbSlideX} instead.
 @example  trigger('slideInRight', [
    transition(':enter',
        useAnimation(HbSlideInRight, {
            params: {
                time: HbSpeed.breeze,
                easing: 'ease-in-out',
                delay: 0,
                parameters: { translationIn: 0, translationOut: '100%' }
            }
        })
 )
 ])
 */
export const HbSlideInRight = animation([
    style({
        transform: 'translateX({{ translationOut }})'
    }),
    animate('{{ time }} {{ delay }} {{ easing }}', style({ transform: 'translateX({{ translationIn }})' }))
]);



/**
 HbSlideOutRight Animation
 @deprecated Use {@link HbSlideX} instead.
 @example  trigger('slideOutRight', [
    transition(':leave',
        useAnimation(HbSlideOutRight, {
            params: {
                time: HbSpeed.breeze,
                easing: 'ease-in-out',
                delay: 0,
                parameters: { translationIn: 0, translationOut: '100%' }
            }
        })
 )
 ])
 */
export const HbSlideOutRight = animation([
    style({
        transform: 'translateX({{ translationIn }})'
    }),
    animate('{{ time }} {{ delay }} {{ easing }}', style({ transform: 'translateX({{ translationOut }})' }))
]);



export const HbSlideX = animation([
    style({
        transform: 'translateX({{ translationStart }})'
    }),
    animate('{{ time }} {{ delay }} {{ easing }}', style({ transform: 'translateX({{ translationEnd }})' }))
]);



/**
 HbSlideInUp Animation
 @deprecated Use {@link HbSlideY} instead.
 @example  trigger('slideInUp', [
    transition(':enter',
        useAnimation(HbSlideInUp, {
            params: {
                time: HbSpeed.breeze,
                easing: 'ease-in-out',
                delay: 0,
                parameters: { translationIn: 0, translationOut: '-100%' }
            }
        })
 )
 ])
 */
export const HbSlideInUp = animation([
    style({
        transform: 'translateY({{ translationOut }})'
    }),
    animate('{{ time }} {{ delay }} {{ easing }}', style({ transform: 'translateY({{ translationIn }})' }))
]);



/**
 HbSlideOutUp Animation
 @deprecated Use {@link HbSlideY} instead.
 @example  trigger('slideOutUp', [
    transition(':leave',
        useAnimation(HbSlideOutUp, {
            params: {
                time: HbSpeed.breeze,
                easing: 'ease-in-out',
                delay: 0,
                parameters: { translationIn: 0, translationOut: '-100%' }
            }
        })
 )
 ])
 */
export const HbSlideOutUp = animation([
    style({
        transform: 'translateY({{ translationIn }})'
    }),
    animate('{{ time }} {{ delay }} {{ easing }}', style({ transform: 'translateY({{ translationOut }})' }))
]);



/**
 HbSlideInDown Animation
 @deprecated Use {@link HbSlideY} instead.
 @example  trigger('slideInDown', [
    transition(':enter',
        useAnimation(HbSlideInDown, {
            params: {
                time: HbSpeed.breeze,
                easing: 'ease-in-out',
                delay: 0,
                parameters: { translationIn: 0, translationOut: '100%' }
            }
        })
 )
 ])
 */
export const HbSlideInDown = animation([
    style({
        transform: 'translateY({{ translationOut }})'
    }),
    animate('{{ time }} {{ delay }} {{ easing }}', style({ transform: 'translateY({{ translationIn }})' }))
]);



/**
 HbSlideOutDown Animation
 @deprecated Use {@link HbSlideY} instead.
 @example  trigger('slideOutDown', [
    transition(':leave',
        useAnimation(HbSlideOutDown, {
            params: {
                time: HbSpeed.breeze,
                easing: 'ease-in-out',
                delay: 0,
                parameters: { translationIn: 0, translationOut: '100%' }
            }
        })
 )
 ])
 */
export const HbSlideOutDown = animation([
    style({
        transform: 'translateY({{ translationIn }})'
    }),
    animate('{{ time }} {{ delay }} {{ easing }}', style({ transform: 'translateY({{ translationOut }})' }))
]);



export const HbSlideY = animation([
    style({
        transform: 'translateY({{ translationStart }})'
    }),
    animate('{{ time }} {{ delay }} {{ easing }}', style({ transform: 'translateY({{ translationEnd }})' }))
]);



export function createHbSlideLeft({ time = HbSpeed.eager, delay = '', easing = 'ease-in-out', properties = { translationIn: '0', translationOut: '-100%' }, stateNames, triggerName = 'slideLeft' }: AnimationConfiguration): AnimationTriggerMetadata {
    return createReversibleAnimation(HbSlideInLeft, HbSlideOutLeft, [{ transform: `translateX(${properties.translationOut})`}, { transform: `translateX(${properties.translationIn})` }], { time, delay, easing, properties, stateNames, triggerName });
}



export function createHbSlideRight({ time = HbSpeed.eager, delay = '', easing = 'ease-in-out', properties = { translationIn: '0', translationOut: '100%' }, stateNames, triggerName = 'slideRight' }: AnimationConfiguration): AnimationTriggerMetadata {
    return createReversibleAnimation(HbSlideInRight, HbSlideOutRight, [{ transform: `translateX(${properties.translationOut})`}, { transform: `translateX(${properties.translationIn})` }], { time, delay, easing, properties, stateNames, triggerName });
}



export function createHbSlideUp({ time = HbSpeed.eager, delay = '', easing = 'ease-in-out', properties = { translationIn: '0', translationOut: '-100%' }, stateNames, triggerName = 'slideUp' }: AnimationConfiguration): AnimationTriggerMetadata {
    return createReversibleAnimation(HbSlideInUp, HbSlideOutUp, [{ transform: `translateY(${properties.translationOut})`}, { transform: `translateY(${properties.translationIn})` }], { time, delay, easing, properties, stateNames, triggerName });
}



export function createHbSlideDown({ time = HbSpeed.eager, delay = '', easing = 'ease-in-out', properties = { translationIn: '0', translationOut: '100%' }, stateNames, triggerName = 'slideDown' }: AnimationConfiguration): AnimationTriggerMetadata {
    return createReversibleAnimation(HbSlideInDown, HbSlideOutDown, [{ transform: `translateY(${properties.translationOut})`}, { transform: `translateY(${properties.translationIn})` }], { time, delay, easing, properties, stateNames, triggerName });
}