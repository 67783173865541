import { Component, Input } from '@angular/core';

@Component({
    selector: 'hb-footer-review-score',
    templateUrl: './footer-review-score.component.html',
    styleUrls: ['./footer-review-score.component.scss']
})
export class FooterReviewScoreComponent {

    /**
     * Number of rviews
     */
    @Input() reviewCount: number;

    @Input() reviewVendorLink: string;

    /**
     * Review score
     */
    @Input() reviewScore: number;

    /**
     * Label printed in front of the review score
     */
    @Input() label = 'Customer review';

    /**
     * Label printed after the review score, but in front of the number of reviews
     */
    @Input() fromLabel = 'from';

    /**
     * Label printed after reviewCount amount
     */
    @Input() entityLabel = 'reviews';

    openReviewVendor() {
        window.open(this.reviewVendorLink, '_blank');
    }

}
