import { AfterViewInit, Component, ContentChildren, Input, OnInit, QueryList, Inject, PLATFORM_ID } from '@angular/core';
import { InfoBarItemComponent } from '../info-bar-item/info-bar-item.component';
import { ReactiveComponent } from '../../../../shared/reactive-component/reactive.component';
import { interval, Observable } from 'rxjs';
import { HbConsoleLogger } from '../../../../shared/console-logger/handlebar-console.logger';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'hb-info-bar-fade',
    templateUrl: './info-bar-fade.component.html',
    styleUrls: ['./info-bar-fade.component.scss']
})
export class InfoBarFadeComponent extends ReactiveComponent implements OnInit, AfterViewInit {

    /**
     * @ignore
     * ContentChild components
     */
    @ContentChildren(InfoBarItemComponent) infoBarItems: QueryList<InfoBarItemComponent>;

    /**
     * @optional
     * provide a height unit for pixel control of infoBar
     * @example 100px
     * @example 5em
     */
    @Input() height: string;

    /**
     * Timer with the interval to showing the next item.
     * Interval value in milliseconds
     */
    mobileInterval$: Observable<number> = interval(3000);

    /**
     * Keep track of mobile index
     */
    mobileIndex = 0;

    /**
     * @ignore
     */
    constructor(@Inject(PLATFORM_ID) private platformId: any) {
        super();
    }

    /**
     * @ignore
     * Set component in Mobile view only if info-bar-fade-items are used and the viewport is smaller than MD
     */
    ngOnInit(): void {
        this.applyAnimationStates();
    }

    /**
     * Handle iterating over items on mobile
     * @ignore
     */
    ngAfterViewInit() {

        if (this.infoBarItems.length > 5) {
            HbConsoleLogger.error('InfoBarFadeComponent', 'You placed more than 5 items in the infobar, which exceeds the supported amount');
        }

        if (isPlatformBrowser(this.platformId)) {
            this.subscriptions.add(
                this.mobileInterval$.subscribe(() => {
                    this.mobileIndex = (this.mobileIndex + 1) % this.infoBarItems.length;
                    this.applyAnimationStates();
                })
            );
        }

    }

    /**
    /**
     * Show and animate bar item
     * @ignore
     */
    applyAnimationStates() {
        Promise.resolve(null).then(() => {
            this.infoBarItems?.map((item, index) => item.fadeAnimation = index === this.mobileIndex);
        });
    }
}
