import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    Inject,
    PLATFORM_ID
} from '@angular/core';
import { LanguageSelectorService } from '../../../language-selector/services/language-selector.service';
import { NavigationExtras, NavigationStart, Router } from '@angular/router';
import { filter, pairwise, pluck, throttleTime } from 'rxjs/operators';
import { NavbarConfig } from '../navbar.interface';
import { ReactiveComponent } from '../../../../shared/reactive-component/reactive.component';
import { fromEvent } from 'rxjs';
import { NavbarService } from '../../services/navbar.service';
import { isPlatformBrowser } from '@angular/common';

export interface NavbarItem {
    title: string;
    url: string;
    routerOptions?: NavigationExtras;
}

@Component({
    selector: 'hb-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent extends ReactiveComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    foldoutMenuOpen = false;

    /**
     * provide menu items
     */
    @Input() menuItems: NavbarItem[];

    /**
     * This turns off the anchors of the Navigation items, and instead emits an event for the parent to
     */
    @Input() emitNavigationMode: boolean;

    /**
     * Provide a config to (dynamically) change the behavior of the navbar
     */
    @Input() config: NavbarConfig; // Provide control of the hamburger Menu

    /**
     * Emits an event when an item has been clicked and the navBar is set to emitNavigationMode
     */
    @Output() itemClicked: EventEmitter<NavbarItem> = new EventEmitter<NavbarItem>();

    /**
     * top area
     * @Ignore
     */
    @ViewChild('topArea', {static: false}) topArea: ElementRef;

    /**
     * Current link
     * @ignore
     */
    activeUrl: string;

    /**
     * Keep track of topbarHeight
     */
    topAreaHeight = 0;

    /**
     * Topbar is fixed on the top
     */
    navbarFixedTop: boolean;


    /**
     * Home item
     * @ignore
     */
    homeItem: NavbarItem = {
        url: '/',
        title: 'home'
    };

    constructor(private languageSelectorService: LanguageSelectorService, private router: Router, private navbarService: NavbarService, @Inject(PLATFORM_ID) private platformId: any) {
        super();
    }

    navigateRoute(item): void {
        this.emitNavigationMode ?
            this.itemClicked.emit(item) :
            this.router.navigateByUrl(item.url, item.routerOptions);
        this.foldoutMenuOpen = false;
    }

    ngOnInit(): void {

        this.activeUrl = this.router.routerState.snapshot.url;

        this.subscriptions.add(
            this.router.events.pipe(
                filter(e => e instanceof NavigationStart)
            ).subscribe((route: NavigationStart) => {
                this.foldoutMenuOpen = false;
                this.activeUrl = route.url;
            })
        );

        if (isPlatformBrowser(this.platformId)) {
            this.subscriptions.add(
                fromEvent(document, 'scroll').pipe(
                    throttleTime(5),
                    filter(() => this.config.topArea),
                    pluck('target', 'scrollingElement', 'scrollTop'),
                    pairwise(),
                    filter(([previousScrollTop, currentScrollTop]) =>
                        (previousScrollTop > currentScrollTop && currentScrollTop <= this.topAreaHeight) ||
                        (previousScrollTop < currentScrollTop && previousScrollTop < this.topAreaHeight)
                    )
                ).subscribe(([,scrollTop]) => {
                    this.navbarFixedTop = scrollTop >= this.topAreaHeight;
                })
            );
        }
    }

    ngAfterViewInit() {
        this.calculateTopbarHeight();
    }

    ngOnChanges() {
        this.calculateTopbarHeight();
    }

    controlFoldoutMenu(open: boolean) {
        this.foldoutMenuOpen = open;
        this.navbarService.provideFoldoutState({
            open: open
        });
    }

    calculateTopbarHeight() {
        Promise.resolve(null).then(() => {
            try {
                this.topAreaHeight = this.topArea.nativeElement.clientHeight;
            } catch {
                this.topAreaHeight = 0;
            }
        }).then(() => {
            this.navbarService.provideTopAreaHeight(this.topAreaHeight);
        });
    }

}
