import { NgModule } from '@angular/core';
import { ContainerModule } from "./container/container.module";

@NgModule({
    imports: [
        ContainerModule,
    ],
    exports: [
        ContainerModule,
    ]
})
export class HbContainerModule { }
