import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DirectivesModule } from './directives/directives.module';
import { DummyErrorDetailsComponent } from './directives/catch-smooth-error.directive';
import { PipesModule } from './pipes/pipes.module';

@NgModule({
    imports: [
        AngularCommonModule,
        DirectivesModule,
        PipesModule,
    ],
    declarations: [
        DummyErrorDetailsComponent,
    ],
    entryComponents: [
        DummyErrorDetailsComponent,
    ],
    exports: [
        DirectivesModule,
        DummyErrorDetailsComponent,
        PipesModule,
    ]
})
export class CommonModule {

}
