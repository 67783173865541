import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
    Type
} from '@angular/core';
import { DialogComponent } from "../component/dialog/dialog.component";
import { DEFAULT_FOCUS_DIALOG_CONFIG, DialogConfig, FocusDialogConfig } from "../dialog.config";
import { DialogInjector } from "../injector/dailog.injector";
import { DialogRef, DialogType } from "./dialog-ref";
import { BaseDialog } from "./base-dialog.class";
import { HbConsoleLogger } from "../../../shared/console-logger/handlebar-console.logger";

@Injectable({
    providedIn: 'root'
})
export class DialogService extends BaseDialog {

    /***
     * Default dialog config
     * @example {closeButton: false, closeViaOverlay: true, width: 500, height: 600}
     */
    focusDialogConfig: FocusDialogConfig = DEFAULT_FOCUS_DIALOG_CONFIG;

    /**
     * Keep track of the ComponentRef so we can destroy it when needed
     */
    dialogComponentRef: ComponentRef<DialogComponent>;

    /**
     * @param componentFactoryResolver We need this to get the factory for given component
     * @param appRef We use this to register the new instance of the component
     * @param injector We allow additional tokens to reach the service. This is used to provide the componentRef
     */
    constructor(
        public componentFactoryResolver: ComponentFactoryResolver,
        public appRef: ApplicationRef,
        public injector: Injector
    ) {
        super(componentFactoryResolver, appRef, injector);
    }

    /***
     * Opens a new dialog with the appropriate component bound in. Make sure the provided component as a parameter is included in the EntryComponents array
     * @param componentType
     * @param settings
     */
    open(componentType: Type<any>, config?: FocusDialogConfig): DialogRef {
        if (this.dialogComponentRef) {
            // At the moment, we allow only 1 dialog being open.
            // While we can ensure that a user will not open 2 dialogs (we hide the website under an overlay of an existing dialog),
            // we cannot ensure that a developer will not open programmatically 2 dialogs
            // and if that happens we loose a reference to an open this.dialogComponentRef and cause a memory leak
            // if we don't do this check.
            HbConsoleLogger.warning('DialogService', `Trying to open a new dialog while an existing dialog ${this.dialogComponentRef.componentType.name} is open.`);
            return;
        }
        const dialogRef = this.AddDialogToDOM(config);
        this.dialogComponentRef.instance.childComponentType = componentType;
        return dialogRef;
    }

    /***
     * Responsible isolated code for adding the dialog box to the view and appending a child component. Element is added to the Body
     * @param componentType
     */
    protected AddDialogToDOM(config?: DialogConfig): DialogRef {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DialogComponent);
        const dialogRef = new DialogRef(DialogType.FOCUS);
        const dependencies = this.addDependenciesToDialog(dialogRef, config);
        const componentRef = componentFactory.create(new DialogInjector(this.injector, dependencies));
        this.appRef.attachView(componentRef.hostView);
        const domElem = (<EmbeddedViewRef<any>>componentRef.hostView).rootNodes[0];
        document.body.appendChild(domElem);
        this.dialogComponentRef = componentRef;
        this.listenToClose(dialogRef);
        return dialogRef;
    }

    /***
     * remove the dialog from the DOM
     */
    protected removeDialogFromDOM(): void {
        this.appRef.detachView(this.dialogComponentRef.hostView);
        this.dialogComponentRef.destroy();
        this.dialogComponentRef = undefined;
    }

}
