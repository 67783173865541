import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { LanguageSelectorService } from "./services/language-selector.service";
import { combineLatest, from, merge } from "rxjs";
import { distinctUntilChanged, filter, switchMap } from "rxjs/operators";
import { createHbExpand } from "../../animations/collapse-expand/collapse-expand.animation";
import { ReactiveComponent } from "../../shared/reactive-component/reactive.component";
import { HbConsoleLogger } from "../../shared/console-logger/handlebar-console.logger";

export interface Language {
    title: string;
    id: string;
}

export interface Country {
    title: string;
    flagId: string;
    id: string;
    supportedLanguages: string[];
}

export interface LanguageSelectorConfig {
    countries: {
        [key: string]: Country;
    };
    languages: {
        [key: string]: Language;
    };
}

export interface LanguageFormUpdateObject {
    country: string;
    language: string;
}

const log = HbConsoleLogger.createLogger(forwardRef(() => LanguageSelectorComponent));

@Component({
    selector: 'hb-language-selector',
    templateUrl: './language-selector.component.html',
    animations: [
        createHbExpand({})
    ],
    styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent extends ReactiveComponent implements OnInit {

    /**
     * Provide a configuration object
     */
    @Input() config: LanguageSelectorConfig;

    /**
     * Save form on blur. This prevents the save button from appearing
     */
    @Input() saveOnBlur = true;

    /**
     * Save button label
     */
    @Input() saveButton = 'Save';

    /**
     * Set a max height of the dropdowns in the language Selector
     */
    @Input() maxHeightDropdown: number = null;

    /**
     * Listen to this event to keep track of submits
     */
    @Output() submitted: EventEmitter<LanguageFormUpdateObject> = new EventEmitter<any>();

    countries: Country[];
    languages: Language[];
    currentCountry: Country;

    languageForm = new FormGroup({
        country: new FormControl('', [Validators.required]),
        language: new FormControl('', [Validators.required])
    }, {
        updateOn: 'submit',
        validators: this.languageIsSupported()
    });


    /**
     * @ignore
     * @param languageSelectorService
     */
    constructor(public languageSelectorService: LanguageSelectorService) {
        super();
    }


    /**
     * @ignore
     */
    ngOnInit(): void {

        // Consume config one time
        try {
            this.countries = Object.values(this.config.countries);
            this.languages = Object.values(this.config.languages);
        } catch {
            log.error('Error parsing Language Selector config object. Please make sure the object contains key-> value pairings for both countries and languages. For more information on the setup please visit the Handlebar documentation');
        }

        // If updateOnblur enabled, any change will submit the form
        if (this.saveOnBlur) {
            this.subscriptions.add(
                this.languageForm.valueChanges.pipe(
                    distinctUntilChanged(),
                    switchMap(v => from(Promise.resolve(v))),
                    filter(() => this.languageForm.dirty)
                ).subscribe(() => {
                    this.submitForm();
                })
            );
        }

        // On country change internally or outside component, set current country so pipe can fetch supported languages
        this.subscriptions.add(
            merge(
                this.languageForm.get('country').valueChanges,
                this.languageSelectorService.countryChanged$
            ).pipe(
                filter(value => value !== null)
            ).subscribe(currentCountry => {
                this.currentCountry = this.config.countries[currentCountry];
            })
        );

        // Listen to outside changes via service and update internally
        this.subscriptions.add(
            combineLatest([
                this.languageSelectorService.countryChanged$,
                this.languageSelectorService.languageChanged$
            ])
                .pipe(
                    distinctUntilChanged()
                )
                .subscribe(([country, language]) => {
                    this.languageForm.reset({
                        country: country,
                        language: language
                    });
                })
        );

    }


    /**
     * If form language and country do not match, it will attempt to set the first available language in the form
     * @ignore
     */
    languageIsSupported(): any {
        return (control: AbstractControl): any => {
            if (!this.config?.countries) {
                return;
            }
            const selectedLanguage = control.get('language');
            const selectedCountry = control.get('country').value;
            const country = this.config.countries[selectedCountry];

            return country?.supportedLanguages.indexOf(selectedLanguage.value) !== -1 ?
                null :
                control.patchValue({
                    language: country?.supportedLanguages[0]
                });
        };
    }


    /**
     * Single point where form data is submitted outside LanguageSelector
     * @ignore
     */
    submitForm(): void {
        this.languageSelectorService.update(this.languageForm.getRawValue());
        this.submitted.emit(this.languageForm.getRawValue());
    }

}
