import { Component, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { createHbExpandFade } from "../../animations/collapse-expand-fade/collapse-expand-fade.animation";
import { HbConsoleLogger } from "../../shared/console-logger/handlebar-console.logger";

export interface OrderSummaryCost {
    label: string;
    amount: any;
}

export interface OrderSummary {
    delivery?: OrderSummaryCost;
    monthly?: OrderSummaryCost[];
    onetime?: OrderSummaryCost[];
}

export interface OrderSummaryLabels {
    totalDelivery: string;
    headerMonthly: string;
    headerOnetime: string;
    totalMonthly: string;
    totalOnetime: string;
    expandSummary: string;
    collapseSummary: string;
    priceHeader: string;
}

const log = HbConsoleLogger.createLogger(forwardRef(() => OrderSummaryComponent));

@Component({
    selector: 'hb-order-summary',
    templateUrl: './order-summary.component.html',
    animations: [
        createHbExpandFade({
            triggerName: 'expand'
        })
    ],
    styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnChanges, OnInit {

    /**
     * Calculate and track total
     */
    totalMonthly = 0;

    /**
     * Calculate and track total
     */
    totalOnetime = 0;

    /**
     * Tracking of open summary
     */
    summaryOpen = false;

    /**
     * Render the prices without any currency token such as € or $.
     */
    @Input() noCurrencyToken = false;

    /**
     * Render the prices with a specific currency. If omitted the default application currency sign will be shown
     */
    @Input() currency: string;

    /**
     * Order summary object
     */
    @Input() orderSummary: OrderSummary;

    /**
     * labels for rendering the component, If a label is ommitted but applicable in your app you may see a white space
     */
    @Input() labels: OrderSummaryLabels;

    calculateTotal(costs: OrderSummary): void {
        if (!costs) {
            return;
        }
        this.totalMonthly = 0;
        this.totalOnetime = this.orderSummary?.delivery?.amount ? this.orderSummary.delivery.amount : 0;
        costs.monthly.forEach(cost => this.totalMonthly += cost.amount);
        costs.onetime.forEach(cost => this.totalOnetime += cost.amount);
    }

    ngOnInit(): void {

        if (!this.labels) {
            log.warning('Labels object is missing. Please add it to the HTML hb-order-summary tag');
        }

        try {
            this.calculateTotal(this.orderSummary);
        } catch {
            log.warning( 'Failed to calculate order amounts. Please check if you have provided a valid orderSummary object');
        }
    }

    ngOnChanges(): void {
        this.calculateTotal(this.orderSummary);
    }

}
