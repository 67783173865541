import { Component } from '@angular/core';
import { createHbFade } from "../../../animations/fade/fade.animations";
import { TooltipConfig } from "../config/tooltip.config";

/**
 * Use {@link TooltipDirective} to display a tooltip using this component as a wrapper
 */
@Component({
    selector: 'hb-tooltip',
    templateUrl: './tooltip.component.html',
    animations: [
        createHbFade({
            time: '0.1s',
            triggerName: 'show'
        })
    ],
    styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {

    constructor(public config: TooltipConfig) {
    }

}
