import { ComponentFactoryResolver, Directive, Injector, Input, TemplateRef, ViewContainerRef, Component, Optional, InjectionToken, Type, Inject } from "@angular/core";

import { smoothenError, smoothReplay } from '../reactive';
import { SmoothError } from '../types';


/**
 * By default {@link CatchSmoothErrorDirective} will display caught {@link SmoothError} details
 * using {@link DummyErrorDetailsComponent}.
 * 
 * If you want to use a custom error view component for your application
 * provide your error details component type with this injection token
 * once in your application module.
 * 
 * <br><br><strong>In your application's module:</strong>
 * 
 * <code>@import { ERROR_DETAILS_COMPONENT } from '@handlebar/angular';</code>
 * 
 * <code>@import { MyAppErrorDetailsComponent } from 'src/components/error-detail-component/my-app-error-detail-comnponent';</code>
 * 
 * ...
 * 
 * <code>@NgModule({</code>
 * 
 * ...
 * 
 * <code>
 * providers: [{
 *                provide: ERROR_DETAILS_COMPONENT,
 *                useValue: MyAppErrorDetailsComponent
 * }]
 * </code>
 * 
 * ...
 * 
 * <code>export class MyApplicationMopdule { }</code>
 * 
 * <br><br><strong>In src/components/error-detail-component/my-app-error-detail-comnponent.ts:</strong>
 * 
 * <code>
 * Component({ template: '&lt;marquee&gt;{{ title }}&lt;/marquee&gt;' })
 * export class MyAppErrorDetailsComponent {
 *      title: string;
 * }
 * </code>
 */
export const ERROR_DETAILS_COMPONENT: InjectionToken<Type<any>> = new InjectionToken('Default component that displays caught error details in an HTML template.');



/**
 * A structural directive that catches a {@link SmoothError} and displays its error message. This directive saves you from writing boilerplate code of displaying data errors in your HTML templates.
 * 
 * You can see <code>*catchSmError</code> as <code>*ngIf/else</code> construct where the <code>if</code> condition is whether the input variable
 * (<code>bike</code> in the example below) is an instance of a {@link SmoothError},
 * in which case the <code>*catchSmError</code> directive will display the error message.
 * The <code>else</code> is everything inside the host element of the <code>*catchSmError</code> directive (<code>{{ bike.Name }}</code> in the example below) - 
 * the directive will display its contents if the input variable is not an instance of {@link SmoothError}.
 * 
 * <strong>Important:</strong> You can provide your own (your application specific) standard error message HTML, by providing
 * {@link ERROR_DETAILS_COMPONENT} <u>once</u> in your application module. Otherwise, by default, <code>*catchSmError</code>
 * will use {@link DummyErrorDetailsComponent} component to display error details.
 * 
 * <strong>Note:</strong> An easy way of wrapping a raw error from an Observable in a {@link SmoothError} is attaching the {@link smoothenError} or {@link smoothReplay} operator at the end of your Observable source.
 * In the example below if you're loading a bike from a bikeClient service in the component file, you can wrap a raw error (HTTP 505 error or network error) thrown by the bikeClient service
 * with a {@link SmoothError} like this: <code>this.bike$ = this.bikeClient.getBike(params.id).pipe(smoothenError())</code>.
 * This will make the raw error pass the <code>async</code> pipe in the HTML template disguised as a plain {@link SmoothError} object.
 * 
 * @example
 * // HTML 
 * <ng-container *ngIf="bike$ | async as bike; else loadingBike">
 *                <ng-container *catchSmError="bike; title: 'Could not load the bike'; class: 'my-class-for-error-on-this-page'">
 *                    {{ bike.Name }}
 *                </ng-container>
 * </ng-container>
 * <ng-template #loadingBike>loading your bike...</ng-template>
 * 
 * // .ts
 * this.bike$ = this.bikeClient.getBike(params.id).pipe(smoothenError());
 */
@Directive({
    selector: '[catchSmError]'
})
export class CatchSmoothErrorDirective {

    @Input('catchSmErrorClass')
    class: string | any;
    @Input('catchSmErrorTitle')
    title = 'Something went wrong here.';

    constructor(private injector: Injector,
        private componentFactoryResolver: ComponentFactoryResolver,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        @Optional() @Inject(ERROR_DETAILS_COMPONENT) private customErrorDetailsComponentType) { }

    @Input()
    set catchSmError(source: any) {
        if (source instanceof SmoothError) {

            const viewComponentFactory = this.componentFactoryResolver.resolveComponentFactory(this.customErrorDetailsComponentType || DummyErrorDetailsComponent),
                errorDetails = viewComponentFactory.create(this.injector);
            
            errorDetails.instance['title'] = this.title;
            errorDetails.instance['source'] = source.reason;
            errorDetails.instance['class'] = this.class;
            errorDetails.changeDetectorRef.detectChanges();

            this.viewContainer.insert(errorDetails.hostView);
            

        } else {
            if (this.viewContainer.length) {
                this.viewContainer.remove(0);
            }
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}


@Component({
    template: '<div [ngClass]="class" [innerHTML]="title"></div>'
})
export class DummyErrorDetailsComponent {
    title: string;
    source: any;
    class: string | any;
}
