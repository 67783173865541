import { Component, Input } from '@angular/core';
import { ControlContainer } from "@angular/forms";
import { AutocompleteComponent } from "../autocomplete/autocomplete.component";
import { InputError } from "../input-error/input-error.component";
import { BaseReactiveFormElement } from "../shared/base-element.reactiveform.class";
import { createHbExpandFade } from "../../../animations/collapse-expand-fade/collapse-expand-fade.animation";
import { Subject } from "rxjs";

export enum InputFormType {
    COLOR = 'color',
    DATETIME = 'datetime-local',
    EMAIL = 'email',
    NUMBER = 'number',
    PASSWORD = 'password',
    SEARCH = 'search',
    TEL = 'tel',
    TEXT = 'text',
    TIME = 'time',
    URL = 'url',
    WEEK = 'week'
}

/**
 * Add an input to a Reactive Form. This is useful for simple text inputs but also supports the more advanced types as defined in the core HTML input element such as email, datetime and url.
 *
 * @example <hb-input controlName="username">Username</hb-input>
 * @example <hb-input controlName="phonenr" width="50%" type="number">How do we reach you?</hb-input>
 */
@Component({
    selector: 'hb-input',
    templateUrl: './input.component.html',
    animations: [
        createHbExpandFade({
            triggerName: 'expand'
        })
    ],
    styleUrls: ['./input.component.scss']
})
export class InputComponent extends BaseReactiveFormElement {

    /**
     * Internal tracking of focus
     * @ignore
     */
    focus = false;

    userTyping$: Subject<string> = new Subject<string>();

    /***
     * autocomplete panel that it should trigger when active
     */
    @Input() autocomplete: AutocompleteComponent;

    /***
     * sets the width of the field in pixels. Please note the block property overrides any value provided here.
     * @example 100px
     * @example 50%
     */
    @Input() width: string;

    /***
     * sets the input to block level. Taking the entire width of its parent container
     */
    @Input() block = false;

    /***
     * provide a validation object to trigger form validation
     */
    @Input() validation: InputError;

    /***
     * Set the input text type
     * @example color, datetime, email, number, password, search, tel, text, time, url, week
     */
    @Input() type: InputFormType = InputFormType.TEXT;

    /**
     * @ignore
     * @param controlContainer
     */
    constructor(controlContainer: ControlContainer) {
        super(controlContainer);
    }

    /***
     * Filters through the autocomplete list as the user is typing in the select box
     * @ignore
     */
    filterAutocomplete(currentInputVal: string): void {
        this.userTyping$.next(currentInputVal);
    }

    /***
     * Sets the value of the form equal to a selected value from autocomplete
     * @param autoCompleteVal
     * @ignore
     */
    changeInput(autoCompleteVal: string): void {
        this.formControl.setValue(autoCompleteVal);
    }

    /***
     * Sets focus to the input field with a small delay
     * @ignore
     */
    setFocus(state: boolean): void {
        setTimeout(() => {
            this.focus = state;
        }, 200);
    }

}
