import { Component, Input } from '@angular/core';

export enum ButtonType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary'
}

/***
 * @example <hb-button>Example text inside</hb-button>
 * @example <hb-button [width]="400" [disabled]="true">disabled longer button</hb-button>
 * @example <hb-button [loading]="true">Button with activated loader</hb-button>
 */

@Component({
    selector: 'hb-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

    /***
     * sets the button to be disabled state
     */
    @Input() disabled = false;

    /**
     * Sets the button to be a submit button
     */
    @Input() submit = false;

    /***
     * Activates the loader and makes the button non-interactive
     */
    @Input() loading = false;

    /**
     * Which version of the button
     */
    @Input() type = ButtonType.PRIMARY;

    /**
     * Full width
     */
    @Input() fullWidth: boolean;

    /***
     *  Set a custom width value, in any unit such as % or px.
     *  @example 100px
     *  @example 50%
     */
    @Input() width: string;

}
