import { TrackByFunction } from '@angular/core';



/**
 * Creates a {@link https://angular.io/api/core/TrackByFunction|TrackByFunction}
 * that uses an item's property of given name as the item ID to otpimise your <code>*ngFor</code> loop performance.
 * 
 * <strong>Example (in your component):</strong>
 * 
 * <code>
 * actionTrack = trackByProperty('ActionId');
 * </code>
 * 
 * <br><br><strong>Then your your template:</strong>
 * 
 * <code>
 * *ngFor="let action of actions; trackBy: actionTrack"
 * </code>
 * @param idPropertyName Name of the item's property to use as an ID of the item.
 * 
 */
export const trackByProperty = (idPropertyName) => {
    return (index: number, object: any) => object ? object[idPropertyName] : object;
};


/**
 * A {@link https://angular.io/api/core/TrackByFunction|TrackByFunction} that tracks items by their <code>Id</code> property.
 */
export const trackById: TrackByFunction<any> = trackByProperty('Id');
