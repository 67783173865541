import { animate, animation, AnimationTriggerMetadata, style } from "@angular/animations";
import { AnimationConfiguration } from "../shared/animation-configuration.interface";
import { HbSpeed } from "../shared/animation-speeds";
import { createReversibleAnimation } from "../shared/reversible-animation";

/**
 CollapseFade Animation
 @example  trigger('slideOutDown', [
 state('hide', style({
                height: 0
            })),
 transition(':leave',
 useAnimation(HbCollapseFade, {
                    params: {
                        time: '0.3s'
                    }
                })
 )
 ])
 */
export const HbCollapseFade = animation([
    style({
        opacity: 1,
        height: '*'
    }),
    animate('{{ time }} {{ delay }} {{ easing }}', style({
        height: 0,
        opacity: 0
    }))
], {
    params: {
        time: HbSpeed.eager,
        delay: '',
        easting: 'ease-in-out'
    }
});

/**
 Expand Fade Animation
 @example  trigger('slideOutDown', [
 state('hide', style({
                height: 0
            })),
 transition(':leave',
 useAnimation(HbExpandFade, {
                    params: {
                        time: '0.3s'
                    }
                })
 )
 ])
 */
export const HbExpandFade = animation([
    style({
        height: 0,
        opacity: 0
    }),
    animate('{{ time }} {{ delay }} {{ easing }}', style({
        height: '*',
        opacity: 1
    }))
], {
    params: {
        time: HbSpeed.eager,
        delay: '',
        easting: 'ease-in-out'
    }
});

/**
 * Create Angular collapse/expand fade animation based on trigger, timing and states. Where the first state is the starting state (collapsed) and the second state is the final state (expanded)
 * @example createHbExpandFade({triggerName: 'myDropdown', timing: '0.6s ease-in-out'})
 */
export function createHbExpandFade({stateNames, time = HbSpeed.eager, delay = '', easing = 'ease-in-out', triggerName = 'expandFade'}: AnimationConfiguration): AnimationTriggerMetadata {
    return createReversibleAnimation(HbExpandFade, HbCollapseFade, [{opacity: 0, height: 0}, {
        opacity: 1,
        height: '*'
    }], {time, delay, easing, triggerName, stateNames});
}
