import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from "./component/dialog/dialog.component";
import { DialogInsertionDirective } from "./directive/insertion.directive";
import { IconModule } from "../icon/icon.module";
import { InlineDialogComponent } from './component/inline-dialog/inline-dialog.component';
import { InlineDialogDirective } from './directive/inline-dialog.directive';


@NgModule({
    declarations: [
        DialogComponent,
        DialogInsertionDirective,
        InlineDialogComponent,
        InlineDialogDirective,
    ],
    imports: [
        CommonModule,
        IconModule
    ],
    entryComponents: [
        DialogComponent,
        InlineDialogComponent
    ],
    exports: [
        DialogComponent,
        InlineDialogComponent,
        DialogInsertionDirective,
        InlineDialogDirective
    ]
})
export class DialogModule {
}
