import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { BaseReactiveFormElement } from "../shared/base-element.reactiveform.class";
import { ControlContainer } from "@angular/forms";
import { HbConsoleLogger } from "../../../shared/console-logger/handlebar-console.logger";

const log = HbConsoleLogger.createLogger(forwardRef(() => OptionCardComponent));

/***
 * Sets a clickable card that acts as a radio button. Submitting the attached value when selected
 * @example <hb-option-card controlName="addon">Any cool HTML here</hb-option-card>
 */
@Component({
    selector: 'hb-option-card',
    templateUrl: './option-card.component.html',
    styleUrls: ['./option-card.component.scss']
})
export class OptionCardComponent extends BaseReactiveFormElement implements OnInit {

    /***
     * The value this option card needs to submit to the form control
     */
    @Input() value: any;

    /**
     * Tell component that it acts as a checkbox by providing a value when inactive
     */
    @Input() inactiveValue: any;

    /**
     * @ignore
     * @param controlContainer
     */
    constructor(controlContainer: ControlContainer) {
        super(controlContainer);
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (!this.controlName) {
            log.error('Please provide a controlName to the hb-option-card component. Example <hb-option-card controlName="addon"></hb-option-card>', 'sdsdsdsd', 'sdsdsdsd');
        }
    }

}
