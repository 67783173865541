import { animate, AnimationReferenceMetadata, AnimationTriggerMetadata, transition, trigger, useAnimation, state, style } from '@angular/animations';
import { AnimationConfiguration } from './animation-configuration.interface';



export function createReversibleAnimation(forwardAnimation: AnimationReferenceMetadata,
    backAnimation: AnimationReferenceMetadata,
    stateStyles: { [key: string]: string | number }[],
    conf: AnimationConfiguration,
    backConf?: AnimationConfiguration): AnimationTriggerMetadata {

    return conf.stateNames && (conf.stateNames.length == 2)
        ? trigger(conf.triggerName, [
            ...conf.stateNames.map((stateName: string, i) => state(stateName, style(stateStyles[i]))),
            transition(`${conf.stateNames[0]} <=> ${conf.stateNames[1]}`, animate(`${conf.time} ${conf.delay} ${conf.easing}`)),
        ])
        : trigger(conf.triggerName, [
            transition(':enter', useAnimation(forwardAnimation, { params: { time: conf.time, delay: conf.delay, easing: conf.easing, ...(conf.properties || {}) } })),
            transition(':leave', useAnimation(backAnimation, { params: backConf ? { time: backConf.time, delay: backConf.delay, easing: backConf.easing, ...(backConf.properties || {}) } : { time: conf.time, delay: conf.delay, easing: conf.easing, ...(conf.properties || {}) } }))
        ]);
}