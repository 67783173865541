import { animate, AnimationMetadata, keyframes, state, style, transition, trigger } from "@angular/animations";
import { HbSpeed } from "../../../../animations/shared/animation-speeds";
import { HbEasing } from "../../../../animations/shared/animation-easings";

export function contextFoldoutPanelAnimation(): AnimationMetadata {
    return trigger('expand', [
        state('open', style({
            transform: 'translateX(0)',
            opacity: 1
        })),
        state('close', style({
            transform: 'translateX(400px)',
            opacity: 0
        })),
        transition('open => close', animate(`${HbSpeed.relaxed} ${HbEasing.easeInOut}`, keyframes([
            style({
                transform: 'translateX(400px)',
                offset: 0.9
            }),
            style({
                opacity: 0,
                offset: 1
            })
        ]))),
        transition('close => open', animate(`${HbSpeed.breeze} ${HbEasing.easeInOut}`, keyframes([
            style({
                opacity: 1,
                offset: 0.1
            }),
            style({
                transform: 'translateX(0px)',
                offset: 1
            }),
        ])))
    ]);
}
