import { animate, animation, style, AnimationTriggerMetadata } from "@angular/animations";
import { AnimationConfiguration } from "../shared/animation-configuration.interface";
import { HbEasing } from '../shared/animation-easings';
import { HbSpeed } from '../shared/animation-speeds';
import { createReversibleAnimation } from '../shared/reversible-animation';



/**
 HbPopIn Animation
 @example  trigger('popIn', [
    transition(':enter',
        useAnimation(HbPopIn, {
            params: {
                time: HbSpeed.breeze,
                easing: 'ease-in-out',
                delay: 0,
                properties: { scaleIn: 1, scaleOut: 0 }
            }
        })
    )
 ])
 */
export const HbPopIn = animation([
    style({
        transform: 'scale({{ scaleOut }})'
    }),
    animate('{{ time }} {{ delay }} {{ easing }}', style({ transform: 'scale({{ scaleIn }})' }))
]);



/**
 HbPopOut Animation
 @example  trigger('popOut', [
    transition(':leave',
        useAnimation(HbPopOut, {
            params: {
                time: HbSpeed.breeze,
                easing: 'ease-in-out',
                delay: 0,
                properties: { scaleIn: 1, scaleOut: 0 }
            }
        })
    )
 ])
 */
export const HbPopOut = animation([
    style({
        transform: 'scale({{ scaleIn }})',
    }),
    animate('{{ time }} {{ delay }} {{ easing }}', style({ transform: 'scale({{ scaleOut }})' }))
]);





/**
 HbPopOut Animation
 @example @Component({
    animations: [ createHbPop({}) ]
 })
 @example @Component({
    animations: [ createHbPop({ delay: '1s' }) ]
 })
 @example @Component({
    animations: [ createHbPop({ time: '2s', triggerName: 'halfPop', properties: { scaleOut: 0.5, scaleIn: 1 } }) ]
 })
 */
export function createHbPop({ time = HbSpeed.eager, delay = '', easing = HbEasing.bounce, properties = { scaleIn: 1, scaleOut: 0 }, stateNames, triggerName = 'pop' }: AnimationConfiguration): AnimationTriggerMetadata {
    return createReversibleAnimation(HbPopIn, HbPopOut, [{ transform: `scale(${ properties.scaleOut })` }, { transform: `scale(${ properties.scaleIn })` }], { time, delay, easing, properties, stateNames, triggerName });
}