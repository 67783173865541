import { Component, Input } from '@angular/core';

export type socialChannelalignType = 'left' | 'right';

@Component({
    selector: 'hb-social-channels',
    templateUrl: './social-channels.component.html',
    styleUrls: ['./social-channels.component.scss']
})
export class SocialChannelsComponent {

    /**
     * Change the size of the icons. Default: 34px
     */
    @Input() iconSize = 34;

    /**
     * provide a link to facebook
     */
    @Input() facebook: string;

    /**
     * provide a link to instagram
     */
    @Input() instagram: string;

    /**
     * provide a link to youtube
     */
    @Input() youtube: string;

    /**
     * Display the social icons in white
     */
    @Input() white: boolean;

    /**
     * Alignment left or right
     * @param link
     */
    @Input() alignment: socialChannelalignType = 'left'

    visitSocial(link: string): void {
        (<any>window).open(link);
    }

}
