import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownItemComponent } from "./dropdown-item/dropdown-item.component";
import { DropdownComponent } from "./dropdown.component";
import { DirectivesModule } from "../../../common/directives/directives.module";
import { DropdownService } from "./service/dropdown.service";
import { IconModule } from '../../icon/icon.module';


@NgModule({
    declarations: [
        DropdownItemComponent,
        DropdownComponent
    ],
    imports: [
        CommonModule,
        DirectivesModule,
        IconModule
    ],
    providers: [
        DropdownService
    ],
    exports: [
        DropdownItemComponent,
        DropdownComponent
    ]
})
export class DropdownModule {
}
