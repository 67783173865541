import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from "rxjs";

export interface LocaleLangUpdate {
    language?: string;
    country?: string;
}

@Injectable({
    providedIn: 'root'
})
export class LanguageSelectorService {

    languageChanged$: Subject<string> = new BehaviorSubject(null);
    countryChanged$: Subject<string> = new BehaviorSubject(null);

    update(update: LocaleLangUpdate): void {
        if (update.country) {
            this.countryChanged$.next(update.country);
        }
        if (update.language) {
            this.languageChanged$.next(update.language);
        }
    }

}
