import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceChannelsComponent } from "./component/service-channels.component";
import { IconModule } from "../../ui/icon/icon.module";


@NgModule({
    declarations: [ServiceChannelsComponent],
    imports: [
        IconModule,
        CommonModule
    ],
    exports: [
        ServiceChannelsComponent
    ]
})
export class ServiceChannelsModule {
}
