import { Pipe, PipeTransform } from '@angular/core';
import { Country, Language } from "../language-selector.component";

/**
 * Return the supported languages based on the current dropdown language value
 */
@Pipe({
    name: 'supportedLanguages'
})
export class SupportedLanguagesPipe implements PipeTransform {

    transform(value: Language[], currentCountry: Country): unknown {
        return value.filter(language => currentCountry?.supportedLanguages.indexOf(language.id) !== -1);
    }

}
