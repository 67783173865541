import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from "./component/navbar/navbar.component";
import { RouterModule } from "@angular/router";
import { IconModule } from "../../ui/icon/icon.module";
import { HamburgerIconModule } from "../../ui/hamburger-icon/hamburger-icon.module";
import { NavbarFoldoutPanelComponent } from './component/navbar-foldout-menu/navbar-foldout-panel.component';
import { NavbarRegionSelectorComponent } from './component/navbar-region-selector/navbar-region-selector.component';
import { NavbarCitySelectorComponent } from './component/navbar-city-selector/navbar-city-selector.component';
import { DropdownModule } from "../../ui/reactive-form/dropdown/dropdown.module";
import { ReactiveFormsModule } from "@angular/forms";
import { OverlayModule } from '../../ui/overlay/overlay.module';


@NgModule({
    declarations: [NavbarComponent, NavbarFoldoutPanelComponent, NavbarRegionSelectorComponent, NavbarCitySelectorComponent],
    imports: [
        CommonModule,
        IconModule,
        RouterModule,
        HamburgerIconModule,
        DropdownModule,
        ReactiveFormsModule,
        OverlayModule
    ],
    exports: [
        NavbarComponent,
        NavbarFoldoutPanelComponent,
        NavbarCitySelectorComponent,
        NavbarRegionSelectorComponent
    ]
})
export class NavbarModule {
}
