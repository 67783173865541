import { Component, HostListener } from '@angular/core';
import { combineLatest, Observable } from "rxjs";
import { LanguageSelectorService } from "./../../../language-selector/services/language-selector.service";
import { ReactiveComponent } from "../../../../shared/reactive-component/reactive.component";

@Component({
    selector: 'hb-navbar-region-selector',
    templateUrl: './navbar-region-selector.component.html',
    styleUrls: ['./navbar-region-selector.component.scss'],
    host: {
        '(document:click)': 'openLanguageDropdown = false'
    }
})
export class NavbarRegionSelectorComponent extends ReactiveComponent {

    openLanguageDropdown = false;

    CountryLanguageState: Observable<[string, string]> = combineLatest([
        this.languageSelectorService.countryChanged$,
        this.languageSelectorService.languageChanged$,
    ]);

    constructor(private languageSelectorService: LanguageSelectorService) {
        super();
    }

    @HostListener('click', ['$event'])
    clickDialog(event) {
        event.stopPropagation();
    }

}
