/**
 * A console logger designed for component based JS development. Useful for exposing potential configuration problems and caught errors in a generic way
 */
export class HbConsoleLogger {

    /**
     * Static method for warning printing
     */
    static warning(componentName: string, ...arg: any[]) {
        console.warn(`[Handlebar] ${componentName} warning:`, arg);
    }

    /**
     * Static method for error printing
     */
    static error(componentName: string, ...arg: any[]) {
        console.error(`[Handlebar] ${componentName} error:`, arg);
    }

    static createLogger(component: Function) {
        return {
            warning(...arg: any[]) {
                return HbConsoleLogger.error(component.name, arg);
            },
            error(...arg: any[]) {
                return HbConsoleLogger.error(component.name, arg);
            }
        };
    }
}
