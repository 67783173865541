import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    OnDestroy,
    Type,
    ViewChild
} from '@angular/core';
import { DialogInsertionDirective } from "../../directive/insertion.directive";
import { FocusDialogConfig } from "../../dialog.config";
import { DialogRef } from "../../service/dialog-ref";
import { NavigationStart, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";

/**
 * Dialog component. This component adds a dark overlay to the page and a white square where you can place any component in. Do not add this template to the DOM directly! This component is always dynamically generated by {@link DialogService},
 * as its content needs to be on the BODY tag for focus takeover.
 * Please note: the component you provide in the Service (in this example myComponent, should be added as an entryComponent in the App Module. This so that the Angular compiler takes the component on build time.
 * @example dialogService.open(MyComponent);
 * @example dialogService.open(MyComponent, {width: 400, height: 300, closeViaOverlay: false, closeButton: true})
 * @example const dialog = dialogService.open(MyComponent); dialog.afterClosed.subscribe(result => {})
 */
@Component({
    selector: 'hb-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements AfterViewInit, OnDestroy {

    /**
     * Put the type of the child component into the class. This allows us to get the Factory needed for dynamically generating an instance
     * @ignore
     */
    childComponentType: Type<any>;

    /**
     * Inline tracking of crossing the mobile breakpoint to apply template logic
     * @ignore
     */
    mobile: boolean;

    /**
     * Inline tracking of viewport width. To allow controlling the focus dialog
     * @ignore
     */
    innerWidth: number;

    /**
     * Keep track of all subscriptions
     * @ignore
     */
    subscriptions: Subscription = new Subscription();

    /**
     * Place to put the instance of the created component. So we can destory it on ngDestory
     * @ignore
     */
    public componentRef: ComponentRef<any>;

    /**
     * Inner directive for placing the childComponent inside the Dialog window
     * @ignore
     */
    @ViewChild(DialogInsertionDirective, {static: false}) insertionPoint: DialogInsertionDirective;

    /**
     * @ignore
     * @param componentFactoryResolver
     * @param cd
     * @param config
     * @param dialogRef
     */
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private cd: ChangeDetectorRef,
        private router: Router,
        private dialogRef: DialogRef,
        public config: FocusDialogConfig
    ) {
    }

    /**
     * Load the child component into the view. And making sure its clear before doing so
     * @param componentType
     * @ignore
     */
    loadChildComponent(componentType: Type<any>): void {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
        const viewContainerRef = this.insertionPoint.viewContainerRef;
        viewContainerRef.clear();
        this.componentRef = viewContainerRef.createComponent(componentFactory);
    }

    /**
     * Load the child component logic in this lifecycle to ensure template is ready
     * @ignore
     */
    ngAfterViewInit(): void {
        if (this.childComponentType) {
            this.loadChildComponent(this.childComponentType);
        }
        this.cd.detectChanges();

        this.subscriptions.add(
            this.router.events.pipe(
                filter(router => router instanceof NavigationStart))
                .subscribe(() => {
                    this.dialogRef.close(null);
                }));

    }

    /**
     * Destory the overlay
     * @ignore
     */
    ngOnDestroy(): void {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
        this.subscriptions.unsubscribe();
    }

    /**
     * user clicks on the overlay. This triggers the onClose observable from dialogService with the value of null
     * @ignore
     */
    onOverlayClick(): null {
        if (!this.config.closeViaOverlay) {
            return;
        }
        this.dialogRef.close(null);
    }

    /**
     * Don't trigger de-activate on click inside dialog
     * @ignore
     * @param e
     */
    onDialogClick(e: MouseEvent): void {
        e.stopPropagation();
        return;
    }

    /**
     * triggered when the user clicks on the overlay cross icon. This triggers the onClose observable from dialogService with the value of null
     * @ignore
     */
    close(): void {
        this.dialogRef.close(null);
    }

}
