import { HttpErrorResponse } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';

import { SmoothError } from '../types';

@Pipe({ name: 'toString' })
export class ToStringPipe implements PipeTransform {

    transform(value: any) {
        if (typeof value === 'string') {
            return value;
        } else if (value instanceof HttpErrorResponse) {
            const httpError: HttpErrorResponse = <HttpErrorResponse> value;
            if (httpError.error != null) {
                if (typeof httpError.error === 'object') {
                    return this.transform(httpError.error);
                } else if (typeof httpError.error === 'string') {
                    try {
                        return this.transform(JSON.parse(httpError.error));
                    } catch (e) {
                        console.warn(e);
                        return '[failed-]';
                    }
                }
            }
            return httpError.message;
        } else if (value instanceof SmoothError) {
            return this.transform((<SmoothError> value).reason);
        } else if (value instanceof Error) {
            return (<Error> value).message;
        } 
        /*
         * implement other possible object stringifying here
         */
        else {
            console.debug('Don\'t know how to stringfy the following value, using default JSON.stringify():', value);
            return JSON.stringify(value);
        }
    }
}