import { of, pipe } from 'rxjs';
import { catchError, filter, map, shareReplay, tap } from 'rxjs/operators';

import { SmoothError } from '../types/error-types';



/**
 * Emits values that have 'length' property (can apply to Array or String then) value
 * meeting the conditions of min and max.
 * 
 * @param min Minimum length.
 * @param max Maximum length.
 */
export const hasLength = (min?: number, max?: number) => pipe(
    filter(arr => !!arr && (min === undefined && 0 < arr['length'] || min <= arr['length']) && (max === undefined || max >= arr['length']))
);


/**
 * Passes on a value only if it is a {@link SmoothError}.
 */
export const isSmoothError = <T> () => pipe(
    filter((input: T) => input !== undefined && !(input instanceof SmoothError))
);



/**
 * Passes on a value if it's not undefined and not a {@link SmoothError}.
 */
export const isValue = <T> () => pipe(
    filter((input: T) => input !== undefined && !(input instanceof SmoothError))
);



/**
 * Chain of {@link smoothenError}, {@link isValue} and [shareReplay]{@link https://www.learnrxjs.io/learn-rxjs/operators/multicasting/sharereplay} (if bufferSize greater than 0).
 * 
 * @param logError Whether to log error in smoothenError()
 * @param bufferSize If grearter than 0 then uses shareReplay(bufferSize) at the end of the pineline.
 */
export const replayValue = (logError = true, bufferSize = 1) => pipe(
    smoothenError(logError),
    isValue(),
    bufferSize == 0 ? tap() : shareReplay(bufferSize),
);



/**
 * If value is {@link SmoothError} unwraps it (throws its wrapped error as raw error).
 * Otherwise passes the value.
 */
export const roughenError = () => pipe(
    map(it => {
        if (it instanceof SmoothError) {
            throw it.reason;
        }
        return it;
    })
);



/**
 * Catches an error thrown in an Observable that it attaches to and passes it on as a success value (smoothens the error)
 * wrapped in an instance of {@link SmoothError} that contains the original error.
 * 
 * <strong>Why?</strong>
 * An Observable by design can throw an error, but... by design it will also terminate the observable (no more success events can go through anymore).
 * We would want to circumvent that behavior in the following cases:
 * * If we want to handle success and error events interchangeably and continously (let another success/error event arrive after one error has occured).
 * * If we want to pass both success and error events to one success callback and check in that one callback whether the event is an actuall success or a wrapped/smoothened error (a {@link SmoothError}).
 * This proves handy when using the [async]{@link https://angular.io/api/common/AsyncPipe} pipe that subscribes to Observables in HTML but only allows for configuring success callback
 * (see using the async pipe with SmoothError in {@link CatchSmoothErrorDirective}).
 * 
 * @param logError Whether to log original error before wrapping.
 */
export const smoothenError = <T>(logError = true) => pipe(
    catchError(err => {
        if (logError) {
            console.error(err);
        }
        return of<T>(<any> new SmoothError(err));
    })
);



/**
 * It's  of {@link smoothenError} with [shareReplay]{@link https://www.learnrxjs.io/learn-rxjs/operators/multicasting/sharereplay} on top.
 * 
 * @param logError 
 * @param bufferSize 
 */
export const smoothReplay = <T>(logError = true, bufferSize = 1) => pipe(
    smoothenError<T>(logError),
    shareReplay<T>(bufferSize),
);
