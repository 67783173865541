import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[hbAutoFocus]'
})
export class AutoFocusDirective {

    constructor(private el: ElementRef) {
        if (!el.nativeElement['focus']) {
            throw new Error('Element does not accept focus.');
        }
    }

    ngOnInit(): void {
        const input: HTMLInputElement = this.el.nativeElement;
        input.focus();
        input.select();
    }
}
