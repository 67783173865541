import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './component/footer/footer.component';
import { IconModule } from '../../ui/icon/icon.module';
import { FooterListComponent } from './component/footer-list/footer-list.component';
import { FooterListItemComponent } from './component/footer-list-item/footer-list-item.component';
import { FooterReviewScoreComponent } from './component/footer-review-score/footer-review-score.component';
import { FooterPaymentMethodsComponent } from './component/footer-payment-methods/footer-payment-methods.component';


@NgModule({
    declarations: [
        FooterComponent,
        FooterListComponent,
        FooterListItemComponent,
        FooterReviewScoreComponent,
        FooterPaymentMethodsComponent
    ],
    imports: [
        CommonModule,
        IconModule
    ],
    exports: [
        FooterComponent,
        FooterListComponent,
        FooterListItemComponent,
        FooterReviewScoreComponent,
        FooterPaymentMethodsComponent
    ]
})
export class FooterModule {
}
