import {
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    HostListener,
    Injector,
    Input,
    OnDestroy,
    Renderer2,
    ViewContainerRef,
    ViewRef
} from '@angular/core';
import { TooltipComponent } from "../component/tooltip.component";
import { TooltipInjector } from "../injector/tooltip.injector";
import { TooltipConfig, TooltipPositionType } from "../config/tooltip.config";
import { HbConsoleLogger } from "../../../shared/console-logger/handlebar-console.logger";

export interface TooltipConfiguration {
    content: string;
    width?: number;
    activateOnClick?: boolean;
    position?: TooltipPositionType;
}

/***
 * Used to display a tooltip over any element. By default the tooltip activates on hover, but you can also trigger it by click.
 * @example <div hbTooltip]="{content: 'Biking all over the world', position: 'TOP', width: 320, activateOnClick: true}">
 *    Click me for a tooltip
 * </div>
 *
 *  * @example <button hbTooltip]="{content: 'I like the way you hover!', position: 'BOTTOM'}">
 *    Hover me
 * </button>
 */
@Directive({
    selector: '[hbTooltip]',
    providers: [
        TooltipComponent
    ]
})
export class TooltipDirective implements OnDestroy {

    /**
     * Inject configuration
     */
    @Input('hbTooltip') config: TooltipConfiguration;


    /***
     * On click toggle the tooltip state
     * @ignore
     */
    @HostListener('click') onClick(): void {
        if (this.config.activateOnClick) {
            this.visible = !this.visible;
            this.visible ?
                this.renderTooltip() :
                this.destroyTooltip();
        }
    }

    /**
     * On hover
     * @ignore
     */
    @HostListener('mouseenter') mouseEnter(): void {
        if (!this.config.activateOnClick) {
            this.renderTooltip();
        }
    }

    /**
     * on leave
     * @ignore
     */
    @HostListener('mouseleave') mouseLeave(): void {
        if (!this.config.activateOnClick) {
            this.destroyTooltip();
        }
    }

    /**
     * Internal tracking of visible
     */
    visible = false;

    /**
     * Store component factory
     */
    componentFactory: ComponentFactory<TooltipComponent>;
    instance: ComponentRef<TooltipComponent>;
    viewInstance: ViewRef;

    /**
     * @ignore
     * @param componentFactoryResolver
     * @param injector
     * @param renderer
     * @param viewContainerRef
     */
    constructor(
        public componentFactoryResolver: ComponentFactoryResolver,
        public injector: Injector,
        private renderer: Renderer2,
        private viewContainerRef: ViewContainerRef) {
        this.setParentStyle();
    }

    /**
     * Sets the element that contains the tooltip to relative so it can be positioned accordingly
     * @ignore
     */
    setParentStyle(): void {
        try {
            this.renderer.setAttribute(this.viewContainerRef.element.nativeElement, 'style', 'cursor: pointer; position: relative;');
        } catch {
            HbConsoleLogger.error('TooltipDirective', 'Could not access or precondition the element it was placed on. Please make sure the hbTooltip directive it is placed on a DOM element');
        }
    }

    /**
     * Render the tooltop. And bind it to the element
     * @ignore
     */
    renderTooltip(): void {
        if (!this.componentFactory) {
            this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(TooltipComponent);
        }
        this.viewContainerRef.clear();
        const config = this.addConfigToTooltip();
        if (!this.instance) {
            this.instance = this.viewContainerRef.createComponent(this.componentFactory, 0, new TooltipInjector(this.injector, config));
        }
        if (this.viewInstance) {
            this.viewContainerRef.insert(this.viewInstance);
        }
        this.viewContainerRef.element.nativeElement.append(this.instance.location.nativeElement);
    }

    /**
     * Destory the tooltip
     */
    destroyTooltip(): void {
        this.viewInstance = this.viewContainerRef.detach(0);
        this.viewContainerRef.clear();
    }

    /**
     * On lifecycle also destory the tooltip
     */
    ngOnDestroy(): void {
        if (this.viewContainerRef) {
            this.destroyTooltip();
        }
    }

    /**
     * Expose the config to the tooltip factory object
     */
    addConfigToTooltip(): WeakMap<any, any> {
        const dependencies = new WeakMap();
        dependencies.set(TooltipConfig, {...new TooltipConfig(), ...this.config});
        return dependencies;
    }

}
