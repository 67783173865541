import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ToStringPipe } from './string.pipes';
import { HasErroredPipe, IsValuePipe } from './reactive';

@NgModule({
    declarations: [
        HasErroredPipe,
        IsValuePipe,
        ToStringPipe,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        HasErroredPipe,
        IsValuePipe,
        ToStringPipe,
    ]
})
export class PipesModule {}
