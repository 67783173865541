import { Component, Input } from '@angular/core';

@Component({
    selector: 'hb-form-section',
    templateUrl: './form-section.component.html',
    styleUrls: ['./form-section.component.scss']
})
export class FormSectionComponent {

    @Input() title: string;
}
