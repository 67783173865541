import { animate, animation, AnimationTriggerMetadata, style } from "@angular/animations";
import { AnimationConfiguration } from "../shared/animation-configuration.interface";
import { HbSpeed } from '../shared/animation-speeds';
import { createReversibleAnimation } from '../shared/reversible-animation';


/**
 CollapseFade Animation
 @example  trigger('fade', [
 state('hide', style({
                opacity: 0
            })),
 transition(':leave',
 useAnimation(HbFadeIn, {
                    params: {
                        time: '0.3s',
                        easing: 'ease-in-out'
                    }
                })
 )
 ])
 */
export const HbFadeIn = animation([
    style({
        opacity: 0,
    }),
    animate('{{ time }} {{ delay }} {{ easing }}', style({ opacity: 1 }))
]);



export const HbFadeOut = animation([
    style({
        opacity: 1,
    }),
    animate('{{ time }} {{ delay }} {{ easing }}', style({ opacity: 0 }))
]);



export function createHbFade({ stateNames, time = HbSpeed.eager, delay = '', easing = 'ease-in-out', triggerName = 'fade' }: AnimationConfiguration): AnimationTriggerMetadata {
    return createReversibleAnimation(HbFadeIn, HbFadeOut, [ { opacity: 0 }, { opacity: 1 } ], { time, delay, easing, triggerName, stateNames });
}