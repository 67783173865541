import { Input, OnInit } from "@angular/core";
import { AbstractControl, ControlContainer, FormControl } from "@angular/forms";

export class BaseReactiveFormElement implements OnInit {

    public formControl: AbstractControl | FormControl;

    /***
     * @description Provide the string value of the FormControlName of the parent form
     */
    @Input() controlName: string;

    constructor(public controlContainer: ControlContainer) {
    }

    ngOnInit(): void {
        if (!this.controlName) {
            return;
        }
        this.formControl = this.controlContainer.control.get(this.controlName);
    }

}
