import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector } from '@angular/core';
import { InlineDialogComponent } from "../component/inline-dialog/inline-dialog.component";
import { DialogComponent } from "../component/dialog/dialog.component";
import { DialogRef, DialogType } from "./dialog-ref";
import {
    DEFAULT_FOCUS_DIALOG_CONFIG,
    DEFAULT_INLINE_DIALOG_CONFIG,
    DialogConfig,
    FocusDialogConfig,
    InlineDialogConfig
} from "../dialog.config";

/***
 * base dialog class. Contains shared logic between the {@link DialogService} and the {@Link InlineDialogService}
 */
@Injectable({
    providedIn: 'root'
})
export abstract class BaseDialog {

    /**
     * DialogComponentRef for storing the created instance of the dialog
     */
    dialogComponentRef: ComponentRef<DialogComponent | InlineDialogComponent>;

    focusDialogConfig: FocusDialogConfig;
    inlineDialogConfig: InlineDialogConfig;

    /**
     * @param componentFactoryResolver {We need this to get the factory for given component}
     * @param appRef We use this to register the new instance of the component
     * @param injector We allow additional tokens to reach the service. This is used to provide the componentRef
     */
    constructor(
        public componentFactoryResolver: ComponentFactoryResolver,
        public appRef: ApplicationRef,
        public injector: Injector
    ) {
    }

    /***
     * @description detatches dialog from body once instance of dialog has been closed
     * @param ref
     */
    protected listenToClose(ref: DialogRef): void {
        ref.afterClosed.subscribe(() => {
            this.removeDialogFromDOM();
        });
    }

    /***
     * @description Used to remove the dialog box from the DOM
     */
    protected abstract removeDialogFromDOM(): void;

    /***
     * Creates a key value pair of two DI tokens that are consumable by the dialog: DialogConfig and DialogRef. DialogRef is used to close
     * the dialog from within. DialogConfig is used to set configurations for this instance of the dialog.
     * @param config
     * @param dialogRef
     * @ignore
     */
    protected addDependenciesToDialog(dialogRef: DialogRef, config?: InlineDialogConfig | DialogConfig): WeakMap<any, any> {
        const dialogDependencies = new WeakMap();
        this.setDialogConfig(dialogRef.dialogType, config);
        dialogDependencies.set(DialogRef, dialogRef);
        if (dialogRef.dialogType === DialogType.FOCUS) {
            dialogDependencies.set(FocusDialogConfig, this.focusDialogConfig);
        } else if (dialogRef.dialogType === DialogType.INLINE) {
            dialogDependencies.set(InlineDialogConfig, this.inlineDialogConfig);
        }
        return dialogDependencies;
    }

    /**
     * Overrides the Inline or Focus dialog configuration
     * @param dialogType
     * @param config
     * @ignore
     */
    protected setDialogConfig(dialogType: DialogType, config?: InlineDialogConfig | DialogConfig): void {
        if (!config) {
            this.focusDialogConfig = DEFAULT_FOCUS_DIALOG_CONFIG;
            this.inlineDialogConfig = DEFAULT_INLINE_DIALOG_CONFIG;
        }

        if (dialogType === DialogType.FOCUS) {
            this.focusDialogConfig = {
                ...this.focusDialogConfig,
                ...config
            };
        } else if (dialogType === DialogType.INLINE) {
            this.inlineDialogConfig = {
                ...this.inlineDialogConfig,
                ...config
            };
        }
    }
}
