import { Component, OnInit, Renderer2 } from '@angular/core';
import { version } from '../../package.json';
import { NavbarService } from '@handlebar/angular';

@Component({
    selector: 'styleguide-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    title = 'styleguide';
    version = version;

    constructor(private navbarService: NavbarService, private renderer: Renderer2) {

    }

    ngOnInit() {
        this.navbarService.foldoutMenuState$.subscribe(state => {
            state.open ?
                this.renderer.addClass(document.body, 'body--fixed') : this.renderer.removeClass(document.body, 'body--fixed');
        });

    }

}
