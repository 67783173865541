import { Component, Input } from '@angular/core';
import {
    borderBottomCollapse,
    borderMiddleCollapse,
    borderTopCollapse,
    hamburgerCollapseAnimation
} from "./animations/hamburger-collapse.animation";
import {
    borderBottomFade,
    borderMiddleFade,
    borderTopFade,
    hamburgerFadeAnimation
} from "./animations/hamburger-fade.animation";

export enum HamburgerAnimationStyle {
    collapse = 'COLLAPSE',
    fade = 'FADE'
}

@Component({
    selector: 'hb-hamburger-icon',
    templateUrl: './hamburger-icon.component.html',
    animations: [
        hamburgerCollapseAnimation,
        borderBottomCollapse,
        borderMiddleCollapse,
        borderTopCollapse,
        hamburgerFadeAnimation,
        borderBottomFade,
        borderMiddleFade,
        borderTopFade,
    ],
    styleUrls: ['./hamburger-icon.component.scss']
})
export class HamburgerIconComponent {

    /**
     * Start state
     */
    @Input() open: boolean;

    /**
     * Define an animation style. Options: collapse | fade
     */
    @Input() animationStyle: HamburgerAnimationStyle = HamburgerAnimationStyle.collapse;

}
