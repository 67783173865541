import { InfoBarFadeComponent } from './components/info-bar-fade/info-bar-fade.component';
import { NgModule } from '@angular/core';
import { InfoBarItemComponent } from './components/info-bar-item/info-bar-item.component';
import { InfoBarRollComponent } from './components/info-bar-roll/info-bar-roll.component';
import { InfoBarInsertionDirective } from './directive/insertion.directive';
import { CommonModule } from '@angular/common';
import { IconModule } from '../icon/icon.module';


@NgModule({
    declarations: [
        InfoBarFadeComponent,
        InfoBarItemComponent,
        InfoBarRollComponent,
        InfoBarInsertionDirective,
    ],
    imports: [
        CommonModule,
        IconModule
    ],
    exports: [
        InfoBarFadeComponent,
        InfoBarItemComponent,
        InfoBarInsertionDirective,
        InfoBarRollComponent
    ]
})
export class InfoBarModule {
}
