import { Observable, Subject } from 'rxjs';

export enum DialogType {
    FOCUS = 'FOCUS',
    INLINE = 'INLINE'
}

export class DialogRef {

    dialogType: DialogType;

    private readonly _afterClosed = new Subject<any>();

    constructor(type: DialogType) {
        this.dialogType = type;
    }


    /***
     * @description used to close the dialog from within a child component
     * @param result
     */
    close(result?: any): void {
        this._afterClosed.next(result);
    }

    /***
     * @description used for a parent to subscribe to the end result of the dialog
     */
    afterClosed: Observable<any> = this._afterClosed.asObservable()
}
