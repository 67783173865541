import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    Input,
    OnDestroy,
    QueryList
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { BaseReactiveFormElement } from '../shared/base-element.reactiveform.class';
import { DropdownItemComponent } from './dropdown-item/dropdown-item.component';
import { debounceTime, distinctUntilChanged, filter, tap, withLatestFrom } from 'rxjs/operators';
import { DropdownService, DropdownValue } from './service/dropdown.service';
import { BehaviorSubject, empty, merge, of, Subject, Subscription } from 'rxjs';
import { IconComponent } from '../../icon/icon.component';

interface DropdownState {
    open: boolean;
    keyboard: boolean;
}

/**
 * Render a dropdown element that slides down a panel with a list of options. This dropdown has keyboard support. We recommend placing text in close proximity of this component so the user always knows what the dropdown is controlling
 * @example     <hb-dropdown controlName="holiday">
 <hb-dropdown-item value="" label="Kies een optie">Kies een optie</hb-dropdown-item>
 <hb-dropdown-item value="newyork" label="New York">New York</hb-dropdown-item>
 <hb-dropdown-item value="amsterdam" label="Amsterdam">Amsterdam</hb-dropdown-item>
 </hb-dropdown>
 */
@Component({
    selector: 'hb-dropdown',
    templateUrl: './dropdown.component.html',
    providers: [DropdownService],
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent extends BaseReactiveFormElement implements AfterViewInit, OnDestroy {

    /**
     * Keep track of option children
     */
    @ContentChildren(DropdownItemComponent) private tabItems: QueryList<any>;
    tabItemList$: BehaviorSubject<DropdownItemComponent[]> = new BehaviorSubject([]);

    /***
     * sets the width of the field.
     * @example 100px
     * @example 50%
     */
    @Input() width: string;

    /**
     * Override the max height of the dropdown panel
     */
    @Input() maxHeight: number;

    /**
     * Keep track of tap focus
     * @ignore
     */
    tapFocus = 0;

    /**
     * User can search dropdown items on click
     */
    @Input() search = false;

    /**
     * Add a search label
     */
    @Input() searchLabel = 'Click to search';

    /**
     * This is the label the user sees before opening the dropdown
     */
    @Input() noValueLabel = 'Pick an option';

    @Input() imageTag: boolean
    /**
     * Sets the label and option icon of the provided value. This is either the label or if not available the value of the item
     * @ignore
     */
    selectedValueLabel: { label: string; icon: IconComponent } = null;

    /**
     * Returns the active state of the dropdown
     * @ignore
     */
    toggleDropdown$: Subject<DropdownState> = new Subject();

    dropdownSubscriptions: Subscription = new Subscription();

    /**
     * Tracking open dropdown state
     */
    activeDropdown: boolean;

    /**
     * Keyboard events triggered
     * @ignore
     */
    navigateViaKeyboard$: Subject<number> = new Subject();

    /**
     * Get dropdown
     * @ignore
     */
    filterDropdown$: Subject<string> = new Subject();


    /***
     * @ignore
     */
    constructor(controlContainer: ControlContainer, private dropdownService: DropdownService, private cd: ChangeDetectorRef) {
        super(controlContainer);
    }

    /**
     * @ignore
     */
    ngAfterViewInit(): void {

        this.tabItemList$.next(this.tabItems.toArray());

        this.dropdownSubscriptions.add(
            // Possible sources of value change
            merge(
                // If a value is preloaded
                this.formControl.value ? of(this.formControl.value) : empty(),
                // If a value is added after lifecycle event
                this.formControl.valueChanges.pipe(debounceTime(100)),
            ).subscribe(val => {
                // Render label
                this.setLabelFromMatchingItem(val);
            })
        );

        // Transcluded items change
        this.tabItems.changes.pipe(filter(items => items !== undefined)).subscribe(() => {
            this.tabItemList$.next(this.tabItems.toArray());
        });

        // Value change comes in from Dropdown Service
        this.dropdownSubscriptions.add(
            this.dropdownService.selectedDropdownItem$.pipe(
                distinctUntilChanged(),
                filter(item => item !== undefined),
                tap((item) => {
                    this.writeValue(item);
                })
            ).subscribe(() => {
                this.toggleDropdown$.next({
                    open: false,
                    keyboard: false
                });
            }));

        // Dropdown closed via keyboard. Write value
        this.dropdownSubscriptions.add(this.toggleDropdown$.pipe(
            filter(state => state.keyboard && state.open === false),
            withLatestFrom(this.tabItemList$),
        ).subscribe(([, items]) => {
            this.writeValue(items[this.tapFocus]);
        }));

        // Dropdown toggles
        this.dropdownSubscriptions.add(this.toggleDropdown$.pipe(
            tap((state) => {
                this.activeDropdown = state.open;
            })
        ).subscribe(() => {
            this.filterDropdown$.next('');
            this.hideDropdownItems(false);
        }));

        // Filter coming through
        this.dropdownSubscriptions.add(this.filterDropdown$
            .subscribe(filter => {
                const filteredList = this.tabItems.filter(item => item.value.toLowerCase().indexOf(filter) !== -1);
                this.hideDropdownItems(true);
                filteredList.forEach(item => item.hide = false);
                this.tabItemList$.next(filteredList);
            }));

        // Keyboard navigation
        this.dropdownSubscriptions.add(this.navigateViaKeyboard$.pipe(
            tap(() => {
                this.tabItemList$.value.forEach(item => {
                    item.focus = false;
                });
            })
        ).subscribe(i => {
            this.tapFocus = i;
            if (this.tapFocus >= this.tabItemList$.value.length) {
                this.tapFocus = 0;
            } else if (this.tapFocus < 0) {
                this.tapFocus = (this.tabItemList$.value.length - 1);
            }
            this.tabItemList$.value[this.tapFocus].focus = true;
        }));

    }

    /**
     * Cancel subs
     * @ignore
     */
    ngOnDestroy(): void {
        this.dropdownSubscriptions.unsubscribe();
    }

    /**
     * Find dropdown element and match label/value pair
     * @ignore
     */
    setLabelFromMatchingItem(value: string): void {
        this.tabItems.forEach(item => {
            if (item.value === value) {
                this.setLabel({
                    value: item.value,
                    icon: item.icon,
                    label: item.label
                });
            }
        });
    }

    /**
     * Write the value to the formControl. And updated the display label
     * @ignore
     */
    writeValue(item: DropdownValue): void {
        this.formControl.setValue(item.value);
        this.formControl.markAsDirty();
        this.setLabel(item);
    }

    /**
     * Set label of the form to represent state of the formGroup
     */
    setLabel(value: DropdownValue): void {
        if (!value) {
            return;
        }
        this.selectedValueLabel = {
            label: value.label,
            icon: value.icon
        };
        this.cd.detectChanges();
    }


    /**
     * Open the dropdown. On keyboard specifically it will always set a focus.
     * When opened with the mouse, only the selected value can be set to focus.
     * @param open
     * @param keyboard
     * @ignore
     */
    toggleDropdown(open: boolean, keyboard = false): void {
        this.toggleDropdown$.next({
            open: open,
            keyboard: keyboard
        });
    }


    hideDropdownItems(hide: boolean): void {
        this.tabItems.forEach(item => item.hide = hide);
    }

    /**
     * Sanitize filter value. Then set all child elements on hide, except for filtered item.
     * Pass filtered items through as list for keyboard tracking
     */
    filterDropdown(input: string): void {
        const sanitizedValue = input.toLowerCase().trim();
        this.filterDropdown$.next(sanitizedValue);
    }

    /**
     * Sets highlight on a dropdown item. This is needed for keyboard support.
     * The focus rolls off from the first and to the last items to keep a continuous arrow movement
     * @ignore
     */
    setKeyHighlight(i: number, e?: KeyboardEvent): void {
        if (e) {
            e.preventDefault();
        }
        this.navigateViaKeyboard$.next(i);
    }

}
