const instant = '.1s';
const swift = '.2s';
const eager = '.3s';
const breeze = '.4s';
const relaxed = '.7s';

export const HbSpeed = {
    instant: instant,
    swift: swift,
    eager: eager,
    breeze: breeze,
    relaxed: relaxed,
};