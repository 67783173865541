import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HamburgerIconComponent } from "./hamburger-icon.component";


@NgModule({
    declarations: [HamburgerIconComponent],
    imports: [
        CommonModule
    ],
    exports: [
        HamburgerIconComponent
    ]
})
export class HamburgerIconModule {
}
