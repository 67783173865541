import { Component, Input } from '@angular/core';
import { ControlContainer } from "@angular/forms";
import { BaseReactiveFormElement } from "../shared/base-element.reactiveform.class";

/**
 * Add a radio button to a reactive form
 * @example   <hb-radio-button controlName="agreement">
                    Do you agree with us?
              </hb-radio-button>
 */
@Component({
    selector: 'hb-radio-button',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss']
})
export class RadioComponent extends BaseReactiveFormElement {

    /***
     * The value this radio button needs to submit to the form control
     */
    @Input() value: string;

    /***
     * @ignore
     */
    constructor(controlContainer: ControlContainer) {
        super(controlContainer);
    }

}
