import { animate, animation, AnimationTriggerMetadata, style } from "@angular/animations";
import { AnimationConfiguration } from "../shared/animation-configuration.interface";
import { HbSpeed } from "../shared/animation-speeds";
import { createReversibleAnimation } from "../shared/reversible-animation";

/**
 Collapse Animation
 @example  trigger('slideOutDown', [
 state('hide', style({
                height: 0
            })),
 transition(':leave',
 useAnimation(HbCollapse, {
                    params: {
                        time: '0.3s',
                        easing: 'ease-in-out
                    }
                })
 )
 ])
 */
export const HbCollapse = animation([
    style({
        height: '*'
    }),
    animate('{{ time }} {{ delay }} {{ easing }}', style({
        height: 0
    }))
], {
    params: {
        time: HbSpeed.eager,
        delay: '',
        easting: 'ease-in-out'
    }
});

/**
 Expand Animation
 @example  trigger('slideOutDown', [
 state('hide', style({
                height: 0
            })),
 transition(':leave',
 useAnimation(HbExpand, {
                    params: {
                        time: '0.3s',
                        easing: 'ease-in-out
                    }
                })
 )
 ])
 */
export const HbExpand = animation([
    style({
        height: 0
    }),
    animate('{{ time }} {{ delay }} {{ easing }}', style({
        height: '*'
    }))
], {
    params: {
        time: HbSpeed.eager,
        delay: '',
        easting: 'ease-in-out'
    }
});

/**
 * Create Angular collapse/expand animation based on trigger, timing and states. Where the first state is the starting state (collapsed) and the second state is the final state (expanded)
 * @example new CreateHbExpand({triggerName: 'myDropdown', time: '0.6s'})
 */
export function createHbExpand({ stateNames, time = HbSpeed.eager, delay = '', easing = 'ease-in-out', triggerName = 'expand' }: AnimationConfiguration): AnimationTriggerMetadata {
    return createReversibleAnimation(HbExpand, HbCollapse, [ { opacity: 0, height: 0 }, { opacity: 1, height: '*' } ], { time, delay, easing, triggerName, stateNames });
}
