import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialChannelsComponent } from "./components/social-channels.component";
import { IconModule } from "../icon/icon.module";


@NgModule({
    declarations: [
        SocialChannelsComponent
    ],
    imports: [
        CommonModule,
        IconModule,
    ],
    exports: [
        SocialChannelsComponent
    ]
})
export class SocialChannelsModule {
}
