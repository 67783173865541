import { Directive, ViewContainerRef } from '@angular/core';

/***
 * Dialog insertion directive is used inside the {@link DialogComponent} and the {@link InlnineDialogComponent} to inject the provided Component inside the dialog.
 * @ignore
 */
@Directive({
    selector: '[hbDialogInsertion]'
})
export class DialogInsertionDirective {

    /**
     * Provide access to the viewContainerRef so we can use it to call the createComponent method from inside the dialogs
     * @param viewContainerRef
     */
    constructor(public viewContainerRef: ViewContainerRef) {
    }

}
