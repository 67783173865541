import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { StepService } from "../../service/step.service";
import { debounceTime, filter } from "rxjs/operators";
import { ReactiveComponent } from "../../../../shared/reactive-component/reactive.component";

/**
 * Component used inside the {@Link StepComponent} to display tabs. You can set a title and edit text for the tab and place any HTML inside.
 * @example <hb-step> <hb-step-item title="first step" activateText="edit"></hb-step-item> </hb-step>
 */
@Component({
    selector: 'hb-step-item',
    templateUrl: './step-item.component.html',
    styleUrls: ['./step-item.component.scss']
})
export class StepItemComponent extends ReactiveComponent implements OnInit, OnDestroy {

    /**
     * Set a title for this step
     * @ignore
     */
    @Input() title: string;

    /**
     * Text rendered in this step to active it. Only applicable to steps that are behind the current active step
     * @ignore
     */
    @Input() activateText = 'Edit';

    /**
     * If true this step is enabled and the user can click on the activateText to open this step
     * @ignore
     */
    enabled = false;

    /**
     * Host binding enabled class
     */
    @HostBinding('class')
    get enabledClass() {
        return this.enabled ? 'steps__inactive--enabled' : '';
    }

    /**
     * Internal tracking of open state
     * @ignore
     */
    active = false;

    /**
     * Host binding active class
     */
    @HostBinding('class')
    get activeClass() {
        return this.active ? '' : 'steps__inactive';
    }

    /**
     * Internal tracking of item index
     * @ignore
     */
    index: number;

    /**
     * Internal track of current active index. Used to know if this step is behind or in front of the active step
     * @ignore
     */
    activeIndex: number;

    /**
     * Total nr of steps in the flow
     * @ignore
     */
    totalItemLength: number;

    /**
     * Stepheight (fixed) used for calculation of stacked step headers
     * @ignore
     */
    stepHeight = 86;

    /**
     * @ignore
     * @param stepsService
     * @param elementRef
     * @param renderer
     */
    constructor(private stepsService: StepService, private elementRef: ElementRef, private renderer: Renderer2) {
        super();
    }

    /**
     * Subscribe to services
     * @ignore
     */
    ngOnInit(): void {

        // Get nr of steps
        this.subscriptions.add(
            this.stepsService.stepList$.subscribe(items => {
                this.totalItemLength = items.length;
            })
        );

        // Get active index and set all elements to false
        this.subscriptions.add(this.stepsService.currentIndex$.pipe(debounceTime(100)).subscribe(item => {
            this.activeIndex = item.index;
            this.enabled = item.index > this.index;
            if (this.activeIndex !== this.index) {
                this.active = false;
                this.stepIsInactive();
            }
        }));

        // If this is the current active item, set active to true
        this.subscriptions.add(this.stepsService.currentIndex$.pipe(
            filter(item => item.index === this.index),
            debounceTime(100)
        ).subscribe(() => {
            this.active = true;
        }));

    }

    /**
     * Make step sticky as its inactive
     * @ignore
     */
    stepIsInactive() {
        this.renderer.setProperty(this.elementRef.nativeElement, 'scrollTop', 0);
        if (this.enabled) {
            this.renderer.setStyle(this.elementRef.nativeElement, 'top', `${this.index * this.stepHeight}px`);
        } else {
            this.renderer.setStyle(this.elementRef.nativeElement, 'bottom', `${(((this.totalItemLength - 1) - this.index) * this.stepHeight)}px`);
        }
    }


    /**
     * The item is activated from within
     * @ignore
     */
    activateItem(): void {
        this.stepsService.openIndex(this.index);
    }

}
