import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { fromEvent, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

/**
 * Provides operations and observables to manage the scrolling of our singe page apps. Service can also provide highlighted elements
 * @example scrollService.scrollTo(200)
 * @example scrollService.scrollAndHighlight(200, 'globalsearch')
 * @example scrollService.highlight$.subscribe(el => alert(`${el} is highlighted`))
 * @example scrollService.scroll$.subscribe(scroll => alert(`listening to scroll, current position Y is ${scroll}`))
 */

@Injectable({
    providedIn: 'root'
})
export class ScrollService {

    /**
     * Subscription for seeing if an animated scroll is ongoing
     */
    isScrolling$: Subject<boolean> = new Subject();

    /**
     * Subscription to highlight element
     */
    highlight$: Subject<string> = new Subject();

    /**
     * Subscription to the offsetY scroll position
     */
    scrollPosition$: Observable<any> = new Observable<any>();

    /**
     * @ignore
     * @param platformId
     */
    constructor(@Inject(PLATFORM_ID) private platformId: any) {
        if (isPlatformBrowser(this.platformId)) {
            this.scrollPosition$ = fromEvent(window, 'scroll').pipe(
                map(() => window.scrollY)
            );
        }
    }

    /**
     * Scroll to a position on the page. If browser supports it use the native scrollTo solution
     */
    scrollTo(offsetTop: number, duration = 600): void {
        this.isScrolling$.next(true);
        this.smoothScrollLegacy(offsetTop, duration)
            .then(() => {
                this.isScrolling$.next(false);
            })
            .catch(() => {
                this.isScrolling$.next(false);
            });
    }

    /**
     * Scroll to a position on the page and trigger a highlight key. The receiver of the highlight$ observable is responsible for handling this string.
     */
    scrollAndHighlight(offsetTop: number, element: string, duration = 600): void {
        this.scrollTo(offsetTop, duration);
        this.highlight$.next(element);
    }

    /**
     * Deactivate the highlight. The receiver of the highlight$ observable is responsible for handling the new value.
     */
    deactivateHighlight(): void {
        this.highlight$.next(null);
    }

    /**
     * Smooth scroll on not supportive browsers
     * Insights: Replace with scrollTo syntax when it supports duration control
     * @ignore
     */
    private smoothScrollLegacy(to: number, duration: number): Promise<void> {
        if (!isPlatformBrowser(this.platformId)) {
            return new Promise(resolve => resolve());
        }
        return new Promise((resolve, reject) => {
            try {
                const element = document.scrollingElement || document.documentElement,
                    start = element.scrollTop,
                    change = to - start,
                    startDate = +new Date();

                const easeInOutQuad: any = (time, start, change, duration) => {
                    time /= duration / 2;
                    if (time < 1) {
                        return change / 2 * time * time + start;
                    }
                    time--;
                    return -change / 2 * (time * (time - 2) - 1) + start;
                };

                const animateScroll: any = () => {
                    const currentDate = +new Date();
                    const currentTime = currentDate - startDate;
                    element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
                    if (currentTime < duration) {
                        requestAnimationFrame(animateScroll);
                    } else {
                        element.scrollTop = to;
                        resolve();
                    }
                };
                animateScroll();
            } catch {
                reject();
            }
        });
    }

}
