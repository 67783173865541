import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from "@angular/forms";

export interface InputError {
    [key: string]: string;
}

/**
 * Display an error message below an Input. For some components you can trigger a validation directly, but if needed use this component for more advanced error handlers
 * @example    <hb-input-error [input]="testForm.get('name')" [validation]="{'required': 'foo', 'minlength': 'bar'}"></hb-input-error>
 */
@Component({
    selector: 'hb-input-error',
    templateUrl: './input-error.component.html',
    styleUrls: ['./input-error.component.scss']
})
export class InputErrorComponent implements OnInit {

    /***
     * Provide the FormControl of the Angular input you want to validate
     */
    @Input() input: FormControl;

    /***
     * provide a conditional validation string. Validation will fire when this validation is in Error state
     */
    @Input() validation: InputError;
    inputValidation: string[][];

    /**
     * @ignore
     */
    ngOnInit(): void {
        this.validation ?
            this.inputValidation = Object.entries(this.validation) :
            [];
    }

}
