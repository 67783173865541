import { animate, animateChild, group, keyframes, query, state, style, transition, trigger } from '@angular/animations';
import { HbSpeed } from "../../../animations/shared/animation-speeds";
import { HbEasing } from "../../../animations/shared/animation-easings";

const middleBarAnimation = `0.01s 0.15s ease-in-out`;

/***
 * @description Triggers the multi animation
 */
export const hamburgerCollapseAnimation = trigger('hamburgerCollapse', [
    transition('0 <=> 1', [
        group([query('@borderTopCollapse, @borderBottomCollapse, @borderMiddleCollapse', animateChild())])
    ])
]);

/***
 * @description animation top bar in hamburger
 */
export const borderTopCollapse = trigger('borderTopCollapse', [
    state('0', style({
        transform: 'translateY(-6px)'
    })),
    state('1', style({
        transform: 'translateY(3px) rotate(45deg)'
    })),
    transition('0 => 1', animate(`${HbSpeed.eager} ${HbEasing.easeInOut}`, keyframes([
        style({transform: 'translateY(-6px)'}),
        style({transform: 'translateY(3px)'}),
        style({transform: 'translateY(3px) rotate(45deg)'})
    ])
    )),
    transition('1 => 0', animate(`${HbSpeed.eager} ${HbEasing.easeInOut}`, keyframes([
        style({transform: 'translateY(3px) rotate(45deg)'}),
        style({transform: 'translateY(3px)',}),
        style({transform: 'translateY(-6px)'})
    ])
    )),
]);

/***
 * @description animation bottom bar in hamburger
 */
export const borderBottomCollapse = trigger('borderBottomCollapse', [
    state('0', style({
        transform: 'translateY(6px)'
    })),
    state('1', style({
        transform: 'translateY(-3px) rotate(-45deg)'
    })),
    transition('0 => 1', animate(`${HbSpeed.eager} ${HbEasing.easeInOut}`, keyframes([
        style({transform: 'translateY(6px)'}),
        style({transform: 'translateY(-3px)'}),
        style({transform: 'translateY(-3px) rotate(-45deg)'})
    ])
    )),
    transition('1 => 0', animate(`${HbSpeed.eager} ${HbEasing.easeInOut}`, keyframes([
        style({transform: 'translateY(-3px) rotate(-45deg)'}),
        style({transform: 'translateY(-3px)'}),
        style({transform: 'translateY(6px)'})
    ])
    )),
]);

/***
 * @description animation middle bar in hamburger
 */
export const borderMiddleCollapse = trigger('borderMiddleCollapse', [
    state('0', style({
        opacity: 1,
    })),
    state('1', style({
        opacity: 0
    })),
    transition('0 => 1', animate(middleBarAnimation)),
    transition('1 => 0', animate(middleBarAnimation, keyframes([
        style({opacity: 0}),
        style({opacity: 1}),
        style({opacity: 1})
    ]))),
]);
