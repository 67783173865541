import { ChangeDetectorRef, Component } from '@angular/core';

/**
 * List item component. This component is used as a child of {@link ListComponent} to make list items. This components supports regular Angular tags such as *ngFor and (click)
 * @example <hb-list-item> My first list item with checks </hb-list-item>
 *      <hb-list-item (click)="goNorth()"> My clickable item </hb-list-item>
 */
@Component({
    selector: 'hb-list-item',
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent {

    /**
     * Internal tracking of a number. This is set when the renderNumber method is called
     */
    number: number;

    /**
     * @ignore
     * @param cd
     */
    constructor(private cd: ChangeDetectorRef) {}

    /**
     * Callable method for making this list item member of an ordered list
     * @param i
     */
    renderNumber(i): void {
        this.number = i + 1;
        this.cd.detectChanges();
    }

}
