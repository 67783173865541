export type DialogConfig = FocusDialogConfig | InlineDialogConfig;

export type DialogAttachDirection = 'LEFT' | 'RIGHT' | 'BOTTOM' | 'TOP' | 'TOPRIGHT' | 'BOTTOMRIGHT' | 'BOTTOMLEFT' | 'TOPLEFT';

export const DEFAULT_FOCUS_DIALOG_CONFIG: FocusDialogConfig = {
    closeButton: true,
    closeViaOverlay: true,
    width: '600px',
    height: '600px',
    data: {}
};

export const DEFAULT_INLINE_DIALOG_CONFIG: InlineDialogConfig = {
    closeButton: true,
    width: 320,
    attach: 'TOP',
    height: 400,
    data: {}
};

export class FocusDialogConfig {
    /**
     * Give the dialog a width and unit of measurement. For example 100px, 50%
     */
    width?: any;

    /**
     * Give the dialog a height and unit of measurement. For example 500px, 70%, auto
     */
    height?:  any;

    /**
     * Make the dialog overflow visible. Useful for dropdowns inside the dialog that may extend the bounds of the dialog box
     */
    overflowVisible?: boolean;

    /**
     * Render a close button. If false, the child component need to close the inner dialog from the inside using DialogRef or via the overlay if enabled
     */
    closeButton?: boolean;

    /**
     * Allow the user to click the dialog away using the overlay wrapper
     */
    closeViaOverlay?: boolean;

    /**
     * Allow the user to pass data into the dialog
     */
    data?: any
}

export class InlineDialogConfig {

    /**
     * Provide a direction for the inline dialog to attach to the parent element. Default: 'BOTTOM'. Allowed values:
     * 'LEFT' | 'RIGHT' | 'BOTTOM' | 'TOP' | 'TOPRIGHT' | 'BOTTOMRIGHT' | 'BOTTOMLEFT' | 'TOPLEFT'
     */
    attach?: DialogAttachDirection;

    /**
     * provide a number. This number will be added to the offsetTop of the dialog. For example. 20 = + 20px offset to top. -20 = -20px offset to top.
     */
    marginTop?: number;

    /**
     * provide a number. This number will be added to the offsetLeft of the dialog. For example. 20 = + 20px offset to left. -20 = -20px offset to left.
     */
    marginLeft?: number;

    /**
     * Give the dialog a height in px
     */
    height?:  any;

    /**
     * Give the dialog a width in px
     */
    width?: any;

    /**
     * Render a close button. If false, the child component need to close the inner dialog from the inside using DialogRef
     */
    closeButton?: boolean;

    /**
     * Allow the user to pass data into the dialog
     */
    data?: any
}
