import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormSectionComponent } from "./form-section.component";


@NgModule({
    declarations: [
        FormSectionComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        FormSectionComponent
    ]
})
export class FormSectionModule {
}
