import { Component, Input } from '@angular/core';

@Component({
    selector: 'hb-overlay',
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent {

    /**
     * Provide a transparent overlay
     */
    @Input() transparent = false;

    /**
     * Make the overlay active
     */
    @Input() active = false;

}
