import { Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DropdownService } from '../service/dropdown.service';
import { IconComponent } from '../../../icon/icon.component';

export interface SelectedDropdownItem {
    value: string;
    label: string;
}

/**
 * This dropdownItem can be used inside a {@Link DropdownComponent} to render options for the user to click inside the dropdown
 * @example <hb-dropdown-item value="">Item</hb-dropdown-item>
 * @example <hb-dropdown-item value="newyork" label="New York">New York</hb-dropdown-item>
 */
@Component({
    selector: 'hb-dropdown-item',
    templateUrl: './dropdown-item.component.html',
    styleUrls: ['./dropdown-item.component.scss']
})
export class DropdownItemComponent implements OnChanges, OnInit {

    /**
     * Check for icon
     */
    @ContentChild(IconComponent) icon: IconComponent;

    /**
     * Internal tracking of focus state
     * @ignore
     */
    focus = false;

    /**
     * Provide a value that is written to the formControl. This value will be saved to the reactive form. If ommitted an empty string will be sent instead
     */
    @Input() value ='';

    /**
     * Provide a label that will be shown to the user when this option has been selected. If omitted the value is used instead
     */
    @Input() label: string;

    /**
     * Make this option unavailable
     */
    @Input() hide = false;

    /**
     * Submits the event with its value when the user selects this option
     * @ignore
     */
    @Output() selected: EventEmitter<SelectedDropdownItem> = new EventEmitter<SelectedDropdownItem>();

    /**
     * @ignore
     * @param dropdownService
     */
    constructor(private dropdownService: DropdownService) {}

    /**
     * Set label to value if ommited
     * @ignore
     */
    ngOnInit(): void {
        if (!this.label) {
            this.label = this.value;
        }
    }

    /**
     * Provide ElementRef to the parent DropdownComponent
     * @ignore
     */
    optionSelected(): void {
        const option = {
            value: this.value,
            label: this.label
        };
        this.dropdownService.selectOption(option);
        this.selected.emit(option);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hide) {
            this.hide = changes.hide.currentValue;
        }
    }

}
