import { Component, Input } from '@angular/core';
import { Router } from "@angular/router";

/**
 * Provide the type of link
 */
export enum LinkType {
    Text = 'TEXT',
    Navigation = 'NAVIGATION'
}

/**
 * Allows you to place a link on the page. Use any event on the tag to invoke behavior, such as (click).
 * @example <hb-link (click)="visitOffer()">Our offers</hb-link>
 * @example <hb-link type="NAVIGATION" (click)="navigateMenu('aboutUs')">About us</hb-link>
 */
@Component({
    selector: 'hb-link',
    templateUrl: './link.component.html',
    styleUrls: ['./link.component.scss']
})
export class LinkComponent {

    constructor(private router: Router) {}

    /**
     * Set the link type. Use "TEXT" for page links and "NAVIGATION" for navigation link elements
     */
    @Input() type = LinkType.Text;

    /**
     * Set the link to block. This will occupy all left and right space and make the link "standalone". Default: false
     */
    @Input() block = false;

    /**
     * Set an optional string to a relative URL. This will help navigate to that path in the Angular app. If you need more fine grain control please add a click event on the element itself
     * @example about/winkel-amsterdam
     * @example mygoal
     * @example /
     */
    @Input() link: string;

    /**
     * Used to trigger Navigate action action when the user has specified a URL link.
     */
    navigate(): void {
        if (this.link) {
            this.router.navigateByUrl(this.link);
        }
    }
}
