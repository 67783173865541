import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from "rxjs";

export interface StepListItem {
    index: number;
}

/**
 * StepService is used to control the StepComponent
 * @example StepService.openIndex(1)
 * @example StepService.currentIndex().subscribe(index => {})
 */
@Injectable({
    providedIn: 'root'
})
export class StepService {

    /**
     * Provide current step item
     * @ignore
     */
    currentIndex$: BehaviorSubject<StepListItem> = new BehaviorSubject({index: 0});

    /**
     * Provides a list of all step items in this flow
     * @ignore
     */
    stepList$: Subject<StepListItem[]> = new Subject();

    /**
     * Set index
     * @param index
     */
    openIndex(index: number) {
        this.currentIndex$.next({index: index});
    }

}
