import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepsComponent } from './components/steps/steps.component';
import { StepItemComponent } from './components/step-item/step-item.component';

@NgModule({
    declarations: [
        StepsComponent,
        StepItemComponent
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        StepsComponent,
        StepItemComponent
    ]
})
export class StepsModule {
}
