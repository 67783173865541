import { Pipe, PipeTransform } from '@angular/core';

import { SmoothError } from '../types/error-types';


/**
 * Returns <code>true</code> if input is an instance of {@link SmoothError}.
 * 
 * @example
 * <ng-container *ngIf="bike$ | async as bike; else loading">
 *                <ng-container *ngIf="bike | hasErrored">
 *                    Could not fetch the bike: {{ bike | toString }}
 *                </ng-container>
 * </ng-container>
 * <ng-template #loading>loading bike...</ng-template>
 */
@Pipe({ name: 'hasErrored' })
export class HasErroredPipe implements PipeTransform {

    transform(value: any): boolean {
        return value instanceof SmoothError;
    }
}



/**
 * Returns <code>true</code> if input is not undefined and not an instance of {@link SmoothError}.
 * 
 * @example
 * <ng-container *ngIf="bike$ | async as bike; else loading">
 *                <ng-container *ngIf="bike | isValue; else bikeError">
 *                    The bike status is {{ bike.Status }}.
 *                </ng-container>
 *                <ng-template #bikeError>
 *                    Could not fetch the bike.
 *                </ng-template>
 * </ng-container>
 * <ng-template #loading>loading bike...</ng-template>
 */
@Pipe({ name: 'isValue' })
export class IsValuePipe implements PipeTransform {

    transform(value: any): boolean {
        return value !== undefined && !(value instanceof SmoothError);
    }
}
