import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderSummaryComponent } from './order-summary.component';
import { IconModule } from "../../ui/icon/icon.module";


@NgModule({
    declarations: [OrderSummaryComponent],
    exports: [
        OrderSummaryComponent
    ],
    imports: [
        CommonModule,
        IconModule
    ]
})
export class OrderSummaryModule { }
